import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useOutletContext, useNavigate } from "react-router-dom";
import {
  Stack,
  Grid,
  Button,
  Typography
} from "@mui/material";
import config from "app/config";
import AuthActions from "app/state/redux/auth_redux";
import getMobileOperatingSystem, { IOS } from "app/services/get_mobile_os";
import styles from "../styles";

function StartFormSection(props) {
  const navigate = useNavigate();
  const { group } = useOutletContext();
  const {
    attemptAppleLogIn,
    attemptFacebookLogIn,
    attemptGoogleLogIn,
    attemptDiscordLogIn
  } = props;

  const socialButtonSx = {
    mt: 2,
    position: "relative",
    pl: 6,
    overflow: "hidden"
  };

  const tryRegister = () => {
    navigate("/register");
  };

  return (
    <Stack justifyContent="space-between" alignSelf="stretch" sx={{ height: "100%" }}>
      <Stack>
        <Grid container justifyContent="center" style={styles.title} sx={{ mt: 8 }}>
          <img alt="" width={225} src={`/${config.ASSET_PATH}/text_logo.png`} />
        </Grid>
        {group?.name ? (
          <Typography
            color="text.light"
            variant="subtitle1"
            align="center"
          >
            {group.name}
          </Typography>
        ) : null}
        <Typography variant="h6" color="primary" align="center" fontWeight="bold" sx={{ mt: 8, mb: 4 }}>
          Sign-In
        </Typography>
        <Button
          variant="contained"
          fullWidth
          color="white"
          sx={socialButtonSx}
          size="large"
          onClick={attemptGoogleLogIn}
          disableElevation
        >
          <img alt="" width={25} src="/assets/g_logo.png" style={styles.socialLogo} />
          Sign in with Google
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="facebook"
          sx={socialButtonSx}
          size="large"
          onClick={attemptFacebookLogIn}
          disableElevation
        >
          <img
            alt=""
            width={25}
            src="/assets/f_logo_RGB-Blue_58.png"
            style={{ ...styles.socialLogo, marginBottom: 5 }}
          />
          Sign in with Facebook
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="discord"
          sx={socialButtonSx}
          size="large"
          onClick={attemptDiscordLogIn}
          disableElevation
        >
          <img
            alt=""
            width={25}
            src="/assets/discord_logo_white.svg"
            style={styles.socialLogo}
          />
          Sign in with Discord
        </Button>
        {getMobileOperatingSystem() === IOS
          ? (
            <Button
              variant="contained"
              fullWidth
              color="white"
              sx={socialButtonSx}
              size="large"
              onClick={attemptAppleLogIn}
              disableElevation
            >
              <img alt="" width={25} src="/assets/a_logo_Black@2x.png" style={styles.socialLogo} />
              Sign in with Apple
            </Button>
          )
          : null}
        <Button
          variant="text"
          fullWidth
          size="large"
          color="text"
          sx={{ mt: 2 }}
          onClick={() => navigate("/login/email")}
          disableElevation
        >
          Sign in with Email
        </Button>
      </Stack>
      <Button
        onClick={tryRegister}
        color="text"
        size="medium"
        sx={{ mb: 6 }}
        disableRipple
        style={styles.linkButton}
      >
        Don&apos;t have an account?
      </Button>
    </Stack>
  );
}

StartFormSection.propTypes = {
  attemptAppleLogIn: PropTypes.func.isRequired,
  attemptDiscordLogIn: PropTypes.func.isRequired,
  attemptFacebookLogIn: PropTypes.func.isRequired,
  attemptGoogleLogIn: PropTypes.func.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string
  })
};

StartFormSection.defaultProps = {
  group: null
};

const mapDispatchToProps = dispatch => ({
  attemptGoogleLogIn: () => dispatch(AuthActions.attemptGoogleLogIn()),
  attemptFacebookLogIn: () => dispatch(AuthActions.attemptFacebookLogIn()),
  attemptAppleLogIn: () => dispatch(AuthActions.attemptAppleLogIn()),
  attemptDiscordLogIn: () => dispatch(AuthActions.attemptDiscordLogIn())
});

export default connect(null, mapDispatchToProps)(StartFormSection);
