import React from "react";
import PropTypes from "prop-types";
import CardSimple from "app/components/card_simple";

function GroupCardSimple(props) {
  const { name, image, id: groupId } = props;
  return (
    <CardSimple
      name={name}
      image={image}
      route={`/c/${groupId}`}
    />
  );
}

GroupCardSimple.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
};

GroupCardSimple.defaultProps = {
  image: null,
  name: "Community"
};

export default GroupCardSimple;
