import { put, takeLatest } from "redux-saga/effects";
import StartupActions, { StartupTypes as types } from "../redux/startup_redux";

// TODO: Add async startup calls here when necessary
function* startup() {
  yield put(StartupActions.startupComplete());
}

export default function startupSagas() {
  return [takeLatest(types.startup, startup)];
}
