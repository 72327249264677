import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isFetching: false,
  events: Map({
    /* Master registry of events referenced in lists by ID */
    byId: Map({}),
    /* Upcoming events in groups a user is a part of */
    userUpcomingEvents: List([]),
  })
});

const types = {
  fetchUserUpcomingEvents: "FETCH_USER_UPCOMING_EVENTS",
  fetchUserUpcomingEventsSuccess: "FETCH_USER_UPCOMING_EVENTS_SUCCESS",
  fetchUserUpcomingEventsFailure: "FETCH_USER_UPCOMING_EVENTS_FAILURE",
  fetchEventDetails: "FETCH_EVENT_DETAILS",
  fetchEventDetailsSuccess: "FETCH_EVENT_DETAILS_SUCCESS",
  fetchEventDetailsFailure: "FETCH_EVENT_DETAILS_FAILURE",
  extendEvent: "EXTEND_EVENT"
};

/* ********* Actions ********* */

const actions = {
  fetchUserUpcomingEvents() {
    return {
      type: types.fetchUserUpcomingEvents
    };
  },
  fetchUserUpcomingEventsSuccess(data) {
    return {
      type: types.fetchUserUpcomingEventsSuccess,
      data
    };
  },
  fetchUserUpcomingEventsFailure() {
    return {
      type: types.fetchUserUpcomingEventsFailure
    };
  },
  fetchEventDetails(data) {
    return {
      type: types.fetchEventDetails,
      data
    };
  },
  fetchEventDetailsSuccess(data) {
    return {
      type: types.fetchEventDetailsSuccess,
      data
    };
  },
  fetchEventDetailsFailure() {
    return {
      type: types.fetchEventDetailsFailure
    };
  },
  extendEvent(eventId, extendedEndDate) {
    return {
      type: types.extendEvent,
      data: { eventId, extendedEndDate }
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchUserUpcomingEvents:
    return state.merge({ isFetching: true });
  case types.fetchUserUpcomingEventsSuccess: {
    const eventsMap = state.getIn(["events", "byId"]);
    return state
      .merge({ isFetching: false })
      .setIn(["events", "byId"], eventsMap.merge(fromJS(action.data.normalized)))
      .setIn(["events", "userUpcomingEvents"], List(action.data.ordered));
  }
  case types.fetchUserUpcomingEventsFailure:
    return state.merge({ isFetching: false });
  case types.fetchEventDetails:
    return state.merge({ isFetching: true });
  case types.fetchEventDetailsSuccess: {
    return state
      .merge({ isFetching: false })
      .setIn(["events", "byId", action.data.id], fromJS(action.data));
  }
  case types.fetchEventDetailsFailure:
    return state.merge({ isFetching: false });
  case types.extendEvent:
    return state.setIn(["events", "byId", action.data.eventId, "endDate"], action.data.extendedEndDate);
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const events = state => state.get("events");

const selectors = {
  getUserUpcomingEvents: memoize(state => events(state)
    .getIn(["events", "userUpcomingEvents"])
    .toJS()),
  getEvent: memoize((state, eventId) => events(state)
    .getIn(["events", "byId", eventId])?.toJS()),
};

export {
  types as EventsTypes,
  reducer as EventsReducer,
  selectors as EventsSelectors
};
export default actions;
