/**
 * Formats a price number value to a currency string, formatting the value to a
 * maximum number of significant figures if provided.
 * E.g. (1234567.89, 4) => "$1,234,000"
 * (1234567.89) => "$1,234,567.89"
 * (125.99, 3) => "$125"
 * @param {number} value
 * @param {number} [maxSigFigs]
 * @returns {string}
 */
export const formatPrice = (value = 0, maxSigFigs = undefined) => {
  const hasDecimal = value % 1 !== 0;
  if (hasDecimal) {
    const currSigFigs = value.toFixed(2).length - 1;
    if (maxSigFigs && currSigFigs > maxSigFigs) {
      const valueRounded = Math.floor(value);
      return valueRounded.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumSignificantDigits: maxSigFigs
      });
    }
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }
  return value?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: maxSigFigs,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

export default {
  formatPrice
};
