import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useOutletContext, useNavigate } from "react-router-dom";
import {
  Stack,
  Grid,
  Button,
  Typography
} from "@mui/material";
import config from "app/config";
import RegistrationActions from "app/state/redux/registration_redux";
import getMobileOperatingSystem, { IOS } from "app/services/get_mobile_os";
import styles from "../styles";

function StartFormSection(props) {
  const navigate = useNavigate();
  const { appLink, group } = useOutletContext();
  const {
    attemptAppleRegister,
    attemptFacebookRegister,
    attemptGoogleRegister,
    attemptDiscordRegister
  } = props;

  const socialButtonSx = {
    mt: 2,
    position: "relative",
    pl: 6,
    overflow: "hidden"
  };

  const logInOrDownload = () => {
    if (appLink && appLink.length) {
      window.location = appLink;
    } else {
      navigate("/login");
    }
  };

  return (
    <Stack justifyContent="space-between" alignSelf="stretch" sx={{ height: "100%" }}>
      <Stack>
        <Grid container justifyContent="center" style={styles.title} sx={{ mt: 8 }}>
          <img alt="" width={225} src={`/${config.ASSET_PATH}/text_logo.png`} />
        </Grid>
        {group?.name ? (
          <Typography
            color="text.light"
            variant="subtitle1"
            align="center"
          >
            {group.name}
          </Typography>
        ) : null}
        <Typography variant="h6" color="primary" align="center" fontWeight="bold" sx={{ mt: 8, mb: 4 }}>
          Sign-Up
        </Typography>
        <Button
          variant="contained"
          fullWidth
          color="white"
          sx={socialButtonSx}
          size="large"
          onClick={attemptGoogleRegister}
          disableElevation
        >
          <img alt="" width={25} src="/assets/g_logo.png" style={styles.socialLogo} />
          Continue with Google
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="facebook"
          sx={socialButtonSx}
          size="large"
          onClick={attemptFacebookRegister}
          disableElevation
        >
          <img
            alt=""
            width={25}
            src="/assets/f_logo_RGB-Blue_58.png"
            style={{ ...styles.socialLogo, marginBottom: 5 }}
          />
          Continue with Facebook
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="discord"
          sx={socialButtonSx}
          size="large"
          onClick={attemptDiscordRegister}
          disableElevation
        >
          <img
            alt=""
            width={25}
            src="/assets/discord_logo_white.svg"
            style={styles.socialLogo}
          />
          Continue with Discord
        </Button>
        {getMobileOperatingSystem() === IOS
          ? (
            <Button
              variant="contained"
              fullWidth
              color="white"
              sx={socialButtonSx}
              size="large"
              onClick={attemptAppleRegister}
              disableElevation
            >
              <img alt="" width={25} src="/assets/a_logo_Black@2x.png" style={styles.socialLogo} />
              Continue with Apple
            </Button>
          )
          : null}
        <Button
          variant="text"
          fullWidth
          size="large"
          color="text"
          sx={{ mt: 2 }}
          onClick={() => navigate("/register/email")}
          disableElevation
        >
          Sign Up with Email
        </Button>
      </Stack>
      <Button
        onClick={logInOrDownload}
        color="text"
        size="medium"
        sx={{ mb: 6 }}
        disableRipple
        style={styles.linkButton}
      >
        Already have an account?
      </Button>
    </Stack>
  );
}

StartFormSection.propTypes = {
  attemptAppleRegister: PropTypes.func.isRequired,
  attemptDiscordRegister: PropTypes.func.isRequired,
  attemptFacebookRegister: PropTypes.func.isRequired,
  attemptGoogleRegister: PropTypes.func.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string
  })
};

StartFormSection.defaultProps = {
  group: null
};

const mapDispatchToProps = dispatch => ({
  attemptGoogleRegister: () => dispatch(RegistrationActions.attemptGoogleRegister()),
  attemptDiscordRegister: () => dispatch(RegistrationActions.attemptDiscordRegister()),
  attemptFacebookRegister: () => dispatch(RegistrationActions.attemptFacebookRegister()),
  attemptAppleRegister: () => dispatch(RegistrationActions.attemptAppleRegister()),
});

export default connect(null, mapDispatchToProps)(StartFormSection);
