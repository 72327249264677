import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CardSimple from "app/components/card_simple";
import { EventsSelectors } from "app/state/redux/events_redux";

function EventCardSimple(props) {
  const { id: eventId } = props;
  const event = useSelector(state => EventsSelectors.getEvent(state, eventId));
  if (!event) {
    return null;
  }
  return (
    <CardSimple
      name={event.name || "Event"}
      image={event.image}
      route={`/e/${eventId}`}
    />
  );
}

EventCardSimple.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EventCardSimple;
