import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  posts: List([]),
  nextPostsStartDate: null,
  isFetching: false,
  isSubmitting: false
});

const types = {
  fetchGroupPosts: "FETCH_GROUP_POSTS",
  fetchGroupPostsSuccess: "FETCH_GROUP_POSTS_SUCCESS",
  fetchGroupPostsFailure: "FETCH_GROUP_POSTS_FAILURE",
  fetchMoreGroupPosts: "FETCH_MORE_GROUP_POSTS",
  fetchMoreGroupPostsSuccess: "FETCH_MORE_GROUP_POSTS_SUCCESS",
  sendPost: "SEND_POST",
  sendPostSuccess: "SEND_POST_SUCCESS",
  sendPostFailure: "SEND_POST_FAILURE",
  newPostReceived: "NEW_POST_RECEIVED",
  newPostSuccess: "NEW_POST_SUCCESS",
  resetPosts: "RESET_POSTS"
};

/* ********* Actions ********* */

const actions = {
  fetchGroupPosts(data) {
    return {
      type: types.fetchGroupPosts,
      data
    };
  },
  fetchGroupPostsSuccess(data) {
    return {
      type: types.fetchGroupPostsSuccess,
      data
    };
  },
  fetchGroupPostsFailure() {
    return {
      type: types.fetchGroupPostsFailure
    };
  },
  fetchMoreGroupPosts(data) {
    return {
      type: types.fetchMoreGroupPosts,
      data
    };
  },
  fetchMoreGroupPostsSuccess(data) {
    return {
      type: types.fetchMoreGroupPostsSuccess,
      data
    };
  },
  sendPost(data) {
    return {
      type: types.sendPost,
      data
    };
  },
  sendPostSuccess() {
    return {
      type: types.sendPostSuccess
    };
  },
  sendPostFailure() {
    return {
      type: types.sendPostFailure
    };
  },
  newPostReceived(data) {
    return {
      type: types.newPostReceived,
      data
    };
  },
  newPostSuccess(data) {
    return {
      type: types.newPostSuccess,
      data
    };
  },
  resetPosts() {
    return {
      type: types.resetPosts
    };
  }
};

/* ********* Reducer ********* */

const calculateNextPostsStartDate = (posts) => {
  if (posts.length === 0) return null;
  const { createDate } = posts[posts.length - 1];
  return new Date(createDate).getTime();
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.sendPost:
    return state.merge({ isSubmitting: true });
  case types.sendPostSuccess:
    return state.merge({ isSubmitting: false });
  case types.sendPostFailure:
    return state.merge({ isSubmitting: false });
  case types.newPostSuccess: {
    setTimeout(() => {
      const chatDiv = document.getElementById("chat-container");
      chatDiv.scrollTop = chatDiv.scrollHeight;
    });
    return state.merge({ posts: fromJS([action.data]).concat(state.get("posts")) });
  }
  case types.fetchGroupPosts:
    return state.merge({ isFetching: true });
  case types.fetchGroupPostsSuccess:
    return state.merge({
      nextPostsStartDate: calculateNextPostsStartDate(action.data),
      isFetching: false,
      posts: fromJS(action.data)
    });
  case types.fetchMoreGroupPosts:
    return state.merge({ isFetching: true });
  case types.fetchMoreGroupPostsSuccess:
    return state.merge({
      nextPostsStartDate: calculateNextPostsStartDate(action.data),
      isFetching: false,
      posts: state.get("posts").concat(fromJS(action.data))
    });
  case types.resetPosts:
    return INIT_STATE;
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const current = state => state.get("posts");
const currentUser = state => state.get("currentUser");
const groups = state => state.get("groups");

const getGroupPosts = (state) => {
  const posts = current(state).get("posts").toJS();

  const currentUserId = currentUser(state).id;
  const groupId = groups(state).get("groupDetails").id;
  const filteredPosts = [];

  posts.forEach((post) => {
    const { usersHiding, deleted } = post;
    if (!deleted) {
      if (usersHiding) {
        if (usersHiding.indexOf(currentUserId) === -1) {
          filteredPosts.push(post);
        }
      } else if (post.groupId === groupId) {
        filteredPosts.push(post);
      }
    }
  });

  return filteredPosts;
};

const getNextPostsStartDate = (state, groupId) =>
  (groupId === current(state).groupId ? current(state).nextPostsStartDate : null);

const selectors = {
  getGroupPosts: memoize(state => getGroupPosts(state)),
  getIsFetching: state => current(state).get("isFetching"),
  getNextPostsStartDate
};

export {
  types as PostsTypes,
  reducer as PostsReducer,
  selectors as PostsSelectors
};
export default actions;
