import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  groups: List([]),
  groupDetails: Map({}),
  isFetching: false,
  isSubmitting: false,
  isFetchingMore: false,
  members: List([]),
  errors: Map({})
});

const types = {
  fetchGroups: "FETCH_GROUPS",
  fetchFeaturedGroups: "FETCH_FEATURED_GROUPS",
  fetchGroupsSuccess: "FETCH_GROUPS_SUCCESS",
  fetchGroupsFailure: "FETCH_GROUPS_FAILURE",
  selectGroup: "SELECT_GROUP",
  queryForGroup: "QUERY_FOR_GROUP",
  fetchGroup: "FETCH_GROUP",
  fetchGroupSuccess: "FETCH_GROUP_SUCCESS",
  fetchGroupFailure: "FETCH_GROUP_FAILURE",
  subscribeToGroupSocket: "SUBSCRIBE_TO_GROUP_SOCKET",
  reset: "RESET"
};

/* ********* Actions ********* */

const actions = {
  fetchGroups() {
    return {
      type: types.fetchGroups
    };
  },
  fetchFeaturedGroups() {
    return {
      type: types.fetchFeaturedGroups
    };
  },
  fetchGroupsSuccess(data) {
    return {
      type: types.fetchGroupsSuccess,
      data
    };
  },
  fetchGroupsFailure() {
    return {
      type: types.fetchGroupsFailure
    };
  },
  selectGroup(data) {
    return {
      type: types.selectGroup,
      data
    };
  },
  queryForGroup(data) {
    return {
      type: types.queryForGroup,
      data
    };
  },
  fetchGroup(data) {
    return {
      type: types.fetchGroup,
      data
    };
  },
  fetchGroupSuccess(data) {
    return {
      type: types.fetchGroupSuccess,
      data
    };
  },
  fetchGroupFailure() {
    return {
      type: types.fetchGroupFailure
    };
  },
  subscribeToGroupSocket(data) {
    return {
      type: types.subscribeToGroupSocket,
      data
    };
  },
  reset() {
    return {
      type: types.reset
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchGroups:
    return state.merge({ isFetching: true });
  case types.fetchFeaturedGroups:
    return state.merge({ isFetching: true });
  case types.fetchGroupsSuccess:
    return state.merge({ isFetching: false, groups: fromJS(action.data) });
  case types.fetchGroupsFailure:
    return state.merge({ isFetching: false });
  case types.selectGroup:
    return state.merge({ groupDetails: fromJS(state.get("groups").toJS().find(g => g.id === action.data)) });
  case types.queryForGroup:
    return state.merge({ isFetching: true });
  case types.fetchGroup:
    return state.merge({ isFetching: true });
  case types.fetchGroupSuccess:
    return state.merge({ isFetching: false, groupDetails: fromJS(action.data) });
  case types.fetchGroupFailure:
    return state.merge({ isFetching: false });
  case types.reset:
    return INIT_STATE;
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const current = state => state.get("groups");

const sortBy = [{
  prop: "isJoined",
  direction: -1
}, {
  prop: "isPending",
  direction: -1
}, {
  prop: "name",
  direction: 1
}];

const getSortedGroups = (state) => {
  const groups = current(state).get("groups").toJS();
  const sortedGroups = groups.sort((a, b) => {
    let i = 0;
    let result = 0;
    while (i < sortBy.length && result === 0) {
      const { direction } = sortBy[i];
      let sort = 0;
      if (a[sortBy[i].prop] < b[sortBy[i].prop]) {
        sort = -1;
      } else {
        sort = (a[sortBy[i].prop] > b[sortBy[i].prop] ? 1 : 0);
      }

      result = direction * sort;
      i += 1;
    }
    return result;
  });
  return sortedGroups;
};

const selectors = {
  getGroup: memoize((state, groupId) =>
    current(state).get("groups").find(g => g.get("id") === groupId)?.toJS()),
  getGroups: memoize(state => current(state).get("groups").toJS()),
  getSortedGroups,
  getIsFetching: state => current(state).get("isFetching"),
  getGroupDetails: state => current(state).get("groupDetails").toJS()
};

export {
  types as GroupsTypes,
  reducer as GroupsReducer,
  selectors as GroupsSelectors
};
export default actions;
