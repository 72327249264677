import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import EventsActions, { EventsSelectors } from "app/state/redux/events_redux";
import GroupsActions, { GroupsSelectors } from "app/state/redux/groups_redux";
import EventCard from "app/containers/event_card_simple";
import GroupCard from "app/containers/group_card_simple";
import ResponsivePage from "../layouts/responsive_page";

function HomePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(EventsActions.fetchUserUpcomingEvents());
    dispatch(GroupsActions.fetchGroups());
  }, []);

  const userUpcomingEvents = useSelector(state => EventsSelectors.getUserUpcomingEvents(state));
  const groups = useSelector(state => GroupsSelectors.getGroups(state));
  return (
    <ResponsivePage title="Home">
      <Typography variant="h6" component="div" align="left" fontWeight="bold" sx={{ mb: 2 }}>
        Auxxit
      </Typography>
      <Grid sx={{ mt: 1, mb: 1 }}>
        <Typography variant="h6" component="div" align="left" sx={{ mb: 1 }}>
          Your Communities
        </Typography>
        <Grid container sx={{ overflowX: "auto" }}>
          <Stack direction="row">
            {groups.map(group => (
              <GroupCard
                key={group.id}
                id={group.id}
                name={group.name}
                image={group.iconMap.medium}
              />
            ))}
          </Stack>
        </Grid>
        {!userUpcomingEvents.length
          ? (
            <Typography variant="subtitle1" color="text.main">
              You have not joined any communities
            </Typography>
          ) : null}
      </Grid>
      <Grid sx={{ mt: 1, mb: 1 }}>
        <Typography variant="h6" component="div" align="left" sx={{ mb: 1 }}>
          Upcoming Events
        </Typography>
        <Grid container sx={{ overflowX: "auto" }}>
          <Stack direction="row">
            {userUpcomingEvents.map(eventId => (
              <EventCard
                key={eventId}
                id={eventId}
              />
            ))}
          </Stack>
        </Grid>
        {!userUpcomingEvents.length
          ? (
            <Typography variant="subtitle1" color="text.main">
              There are no upcoming events to show
            </Typography>
          ) : null}
      </Grid>
    </ResponsivePage>
  );
}

export default HomePage;
