import api from "app/services/api";
import { getAuth } from "firebase/auth";

async function refreshAuthToken() {
  const idToken = await getAuth().currentUser?.getIdToken(true);
  if (!idToken) {
    return false;
  }

  api.client.setAccessToken(idToken);

  return true;
}

export default refreshAuthToken;
