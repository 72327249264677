const paginationHelpers = {
  getNextPage({ currentPage, numberOfPages }) {
    return currentPage < numberOfPages ? currentPage + 1 : null;
  },
  filterOutDuplicates(list1, list2) {
    return list1.filter(item =>
      !(list2.find(i => i.id === item.id)));
  }
};

export default paginationHelpers;
