import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Grid
} from "@mui/material";
import { useRouteError } from "react-router-dom";
import config from "app/config";
import { darkTheme } from "app/themes/theme";
import Page from "../layouts/grid_page";

const ErrorPage = function ErrorPage() {
  const error = useRouteError();
  return (
    <Page
      title="Error"
      contentAlign="center"
      contentJustify="center"
    >
      <ThemeProvider theme={darkTheme}>
        <Grid container justifyContent="center">
          <img alt="" width={50} src={`/${config.ASSET_PATH}/icon_logo.png`} style={{ marginBottom: 10 }} />
        </Grid>
        <Typography variant="h6" component="div" align="center" color="text.main" fontWeight="bold" sx={{ marginBottom: 1 }}>
          Oops!
        </Typography>
        <Typography variant="h7" component="div" align="center" color="text.main" sx={{ marginBottom: 1 }}>
          Sorry, an unexpected error has occurred.
        </Typography>
        <Typography variant="h7" component="i" align="center" color="text.light">
          {error.statusText || error.message}
        </Typography>
      </ThemeProvider>
    </Page>
  );
};

export default ErrorPage;
