import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

class GridPage extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    contentAlign: PropTypes.string,
    contentJustify: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    contentAlign: "flex-start",
    contentJustify: "flex-start"
  };

  componentDidMount() {
    this.setTitle();
  }

  setTitle() {
    const { title } = this.props;
    document.title = title;
  }

  render() {
    const { children, contentAlign, contentJustify } = this.props;
    const styles = {
      height: "100%",
      width: "100%",
      overflowY: "auto"
    };
    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={styles}
      >
        <Grid
          container
          item
          direction="column"
          flex={1}
          justifyContent={contentJustify}
          alignItems={contentAlign}
        >
          {children}
        </Grid>
      </Grid>
    );
  }
}

export default GridPage;
