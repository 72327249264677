import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  groupDetails: Map({}),
  events: List([]),
  eventItems: List([]),
  items: List([]),
  soldItems: List([]),
  acceptedItems: List([]),
  isFetching: false,
  errors: Map({})
});

const types = {
  fetchCommunityInfo: "FETCH_COMMUNITY_INFO",
  fetchCommunityInfoSuccess: "FETCH_COMMUNITY_INFO_SUCCESS",
  fetchCommunityInfoFailure: "FETCH_COMMUNITY_INFO_FAILURE",
  fetchPublicEventItems: "FETCH_PUBLIC_EVENT_ITEMS",
  fetchPublicEventItemsSuccess: "FETCH_PUBLIC_EVENT_ITEMS_SUCCESS",
  fetchPublicEventItemsFailure: "FETCH_PUBLIC_EVENT_ITEMS_FAILURE",
  reset: "RESET"
};

/* ********* Actions ********* */

const actions = {
  fetchCommunityInfo(data) {
    return {
      type: types.fetchCommunityInfo,
      data
    };
  },
  fetchCommunityInfoSuccess(data) {
    return {
      type: types.fetchCommunityInfoSuccess,
      data
    };
  },
  fetchCommunityInfoFailure() {
    return {
      type: types.fetchCommunityInfoFailure
    };
  },
  fetchPublicEventItems(data) {
    return {
      type: types.fetchPublicEventItems,
      data
    };
  },
  fetchPublicEventItemsSuccess(data) {
    return {
      type: types.fetchPublicEventItemsSuccess,
      data
    };
  },
  fetchPublicEventItemsFailure() {
    return {
      type: types.fetchPublicEventItemsFailure
    };
  },
  reset() {
    return {
      type: types.reset
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchCommunityInfo:
    return state.merge({ isFetching: true });
  case types.fetchCommunityInfoSuccess:
    return state.merge({
      isFetching: false,
      groupDetails: fromJS(action.data.group),
      events: fromJS(action.data.events),
      items: fromJS(action.data.items),
      soldItems: fromJS(action.data.soldItems),
      acceptedItems: fromJS(action.data.acceptedItems)
    });
  case types.fetchCommunityInfoFailure:
    return state.merge({ isFetching: false });
  case types.fetchPublicEventItems:
    return state.merge({ isFetching: true });
  case types.fetchPublicEventItemsSuccess:
    return state.merge({ isFetching: false, eventItems: fromJS(action.data) });
  case types.fetchPublicEventItemsFailure:
    return state.merge({ isFetching: false, });
  case types.reset:
    return INIT_STATE;
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const current = state => state.get("communityInfo");

const selectors = {
  getIsFetching: state => current(state).get("isFetching"),
  getGroupDetails: state => current(state).get("groupDetails").toJS(),
  getEvents: state => current(state).get("events").toJS(),
  getItems: state => current(state).get("items").toJS(),
  getSoldItems: state => current(state).get("soldItems").toJS(),
  getAcceptedItems: state => current(state).get("acceptedItems").toJS(),
  getEventDetails: memoize((state, eventId) =>
    current(state).get("events").find(e => e.get("id") === eventId)?.toJS()),
  getEventItems: state => current(state).get("eventItems").toJS(),
  getItemDetails: memoize((state, itemId) =>
    current(state).get("items")?.find(i => i.get("id") === itemId)?.toJS()
      || current(state).get("eventItems")?.find(i => i.get("id") === itemId)?.toJS()
      || current(state).get("acceptedItems")?.find(i => i.get("id") === itemId)?.toJS()
      || current(state).get("soldItems")?.find(i => i.get("id") === itemId)?.toJS())
};

export {
  types as CommunityInfoTypes,
  reducer as CommunityInfoReducer,
  selectors as CommunityInfoSelectors
};
export default actions;
