import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Container
} from "@mui/material";
import config from "app/config";
import { darkTheme } from "app/themes/theme";
import getMobileOperatingSystem, { IOS, ANDROID } from "app/services/get_mobile_os";
import Page from "../layouts/grid_page";

const AppStorePage = function AppStorePage() {
  const mobileOs = getMobileOperatingSystem();
  let appStoreName = "app store";
  if (mobileOs === IOS) {
    appStoreName = "App Store";
  } else if (mobileOs === ANDROID) {
    appStoreName = "Google Play Store";
  }
  return (
    <Page
      title="Featured"
      contentAlign="center"
      contentJustify="center"
    >
      <ThemeProvider theme={darkTheme}>
        <Container
          maxWidth="sm"
          sx={{
            position: "relative",
            height: "100%",
            mt: 1,
            mb: 1,
            pl: 4,
            pr: 4
          }}
        >
          <Grid container justifyContent="center" sx={{ mt: 16 }}>
            <img alt="" width={225} src={`/${config.ASSET_PATH}/text_logo.png`} />
          </Grid>
          <Typography variant="h5" component="div" align="center" color="text.main" fontWeight="bold" sx={{ mt: 6, mb: 1 }}>
            Now let&apos;s download the app!
          </Typography>
          <Typography variant="body2" component="div" align="center" color="text.main" sx={{ mt: 6, mb: 1 }}>
            {`In a few seconds, you will be taken to the ${appStoreName}${mobileOs === IOS ? ", when prompted, select:" : "."}`}
          </Typography>
          {mobileOs === IOS
            ? (
              <Typography component="div" color="text.main" align="center" sx={{ mt: 2 }}>
                <Typography variant="h7" fontWeight="bold" component="span" color="text.main">
                  &quot;Get the App&quot;
                </Typography>
                <Typography variant="body2" component="span" align="center" color="text.main">
                  {" and "}
                </Typography>
                <Typography variant="h7" fontWeight="bold" component="span" color="text.main">
                  &quot;Allow Paste&quot;
                </Typography>
              </Typography>
            ) : null}
        </Container>
      </ThemeProvider>
    </Page>
  );
};

export default AppStorePage;
