import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Container
} from "@mui/material";

export default function BottomErrorMessage({ message }) {
  return (
    <Container
      sx={{
        position: "absolute",
        bottom: 5,
        left: 0,
        right: 0,
        margin: "auto"
      }}
    >
      <Typography
        color="error"
        variant="body2"
        align="center"
      >
        {message}
      </Typography>
    </Container>
  );
}

BottomErrorMessage.propTypes = {
  message: PropTypes.string
};

BottomErrorMessage.defaultProps = {
  message: ""
};
