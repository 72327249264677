import currentUser from "./current_user_model";
import group from "./group_model";
import post from "./post_model";
import { sanitizeObjectIds } from "./helpers";

export default {
  ...currentUser,
  ...group,
  ...post,
  sanitize: sanitizeObjectIds
};
