import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Box,
  Card,
  CardMedia,
  CardContent
} from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import config from "app/config";
import EventsActions, { EventsSelectors } from "app/state/redux/events_redux";
import ItemsActions, { ItemsSelectors } from "app/state/redux/items_redux";
import ItemCardSimple from "app/containers/item_card_simple";
import ResponsivePage from "../layouts/responsive_page";

const { EVENT_TYPE } = config;

const EventPage = function EventPage() {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const eventDetails = useSelector(
    state => EventsSelectors.getEvent(state, eventId)
  ) || {};

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(EventsActions.fetchEventDetails(eventId));
  }, []);

  useEffect(() => {
    if (eventDetails.type) {
      dispatch(ItemsActions.fetchEventItems({ eventId, eventType: eventDetails.type }));
    }
  }, [eventDetails.type]);

  const items = useSelector(state => ItemsSelectors.getEventItems(state)) || [];

  const { startDate, endDate, type } = eventDetails;
  const startDateJs = new Date(startDate);
  const startDigital = startDateJs.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  const endDateJs = new Date(endDate);
  const endDigital = endDateJs.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

  const isAuction = type === EVENT_TYPE.AUCTION;
  const isLive = startDateJs < new Date() && endDateJs > new Date();

  return (
    <ResponsivePage title="Event">
      <Grid sx={{ mt: 1, mb: 1 }}>
        <Card sx={{ display: "flex", bgcolor: "transparent", backgroundImage: "none" }}>
          <CardMedia
            component="img"
            sx={{
              height: {
                xs: 150,
                sm: 200,
              },
              width: {
                xs: 150,
                sm: 200,
              },
              minWidth: {
                xs: 150,
                sm: 200
              }
            }}
            image={eventDetails.image}
            alt="Auction night!"
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ bgcolor: "transparent", backgroundImage: "none", pt: 0 }}>
              <Typography variant="h6" color="text.main" fontWeight="bold">
                {eventDetails.name || "Upcoming Event"}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {isLive ? `Live until ${endDigital}` : `${startDigital} - ${endDigital}`}
              </Typography>
              <Typography variant="subtitle2" color="text.main" sx={{ mt: 1 }}>
                {eventDetails.description || "There's an upcoming event in this community!"}
              </Typography>
              {isLive ? (
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  size="medium"
                  onClick={() => navigate(`/e/${eventId}/live`)}
                  endIcon={<ArrowForward />}
                >
                  It&apos;s Live!
                </Button>
              ) : (
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  size="medium"
                  onClick={() => navigate(`/e/${eventId}/items/new`)}
                  disableElevation
                >
                  Add Item
                </Button>
              )}
            </CardContent>
          </Box>
        </Card>
        <Grid sx={{ mt: 3 }}>
          <Typography variant="h6" color="text.main" fontWeight="bold" sx={{ mb: 1 }}>
            {`Items in ${isAuction ? "Auction" : "Market"}`}
          </Typography>
          <Grid
            container
            sx={{
              justifyContent: {
                xs: "space-around",
                sm: "flex-start"
              }
            }}
          >
            {items.map(itemId => (
              <ItemCardSimple
                key={itemId}
                id={itemId}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </ResponsivePage>
  );
};

export default EventPage;
