import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
  Divider,
  Grid,
  Container
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PeopleIcon from "@mui/icons-material/People";
import config from "app/config";
import { theme } from "app/themes";
import GroupsActions, { GroupsSelectors } from "app/state/redux/groups_redux";
import Page from "../layouts/grid_page";
import styles from "./styles";

class FeaturedPage extends Component {
  static propTypes = {
    fetchFeaturedGroups: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape()),
    selectGroup: PropTypes.func.isRequired
  };

  static defaultProps = {
    groups: []
  };

  componentDidMount() {
    const { fetchFeaturedGroups } = this.props;
    fetchFeaturedGroups();
  }

  selectGroup = groupId => () => {
    const { selectGroup } = this.props;
    selectGroup(groupId);
  };

  renderFeaturedGroups = () => {
    const { groups } = this.props;
    return groups.map(g => (
      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        xs={12}
        sm={6}
        md={4}
        key={g.id}
        sx={{ width: "100%" }}
      >
        <CardMedia
          component="img"
          alt="group icon"
          height={140}
          image={g.icon}
        />
        <CardContent sx={{ pb: 0 }}>
          <Typography gutterBottom variant="h7" color="text.main" component="div">
            {g.name}
          </Typography>
          <Typography variant="body2" color="text.light">
            {g.description}
          </Typography>
        </CardContent>
        <Container>
          <CardActions sx={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
            <RouterLink to="/register" state={{ groupId: g.id }} style={{ display: "flex" }}>
              <Button size="small" color="secondary" onClick={this.selectGroup(g.id)}>
                Join
                <ArrowForwardIcon sx={{ ml: 1, mr: 1, fontSize: 18 }} />
              </Button>
            </RouterLink>
            <Grid container alignItems="center">
              <PeopleIcon color="text.main" />
              <Typography variant="body2" color="text.light">{g.memberCount}</Typography>
            </Grid>
          </CardActions>
          <Divider flexItem />
        </Container>
      </Grid>
    ));
  };

  render() {
    return (
      <Page
        title="Featured"
        contentAlign="center"
        contentJustify="center"
      >
        <ThemeProvider theme={theme}>
          <Card raised sx={{ mt: 1, mb: 1 }}>
            <CardContent>
              <Grid container justifyContent="center" style={styles.title}>
                <img alt="" width={225} src={`/${config.ASSET_PATH}/text_logo.png`} />
              </Grid>
              <Typography variant="h6" component="div" align="center" color="text.main" fontWeight="bold">
                You&apos;re new here...and we kinda are too!
              </Typography>
              <Typography variant="subtitle" component="div" align="center" color="text.light">
                We&apos;re powering just a few beta communities right now, but if one
                strikes your fancy, simply request to join!
              </Typography>
            </CardContent>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {this.renderFeaturedGroups()}
            </Grid>
          </Card>
        </ThemeProvider>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  groups: GroupsSelectors.getGroups(state)
});

const mapDispatchToProps = dispatch => ({
  selectGroup: groupId => dispatch(GroupsActions.selectGroup(groupId)),
  fetchFeaturedGroups: () => dispatch(GroupsActions.fetchFeaturedGroups())
});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedPage);
