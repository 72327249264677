import api, { publicClient as publicApi } from "./client";

function logIn(params) {
  return publicApi.post("/users/login", params);
}

function logOut(params) {
  return api.post("/users/revoke", params);
}

function signUp(params) {
  return publicApi.post("/users/register", params);
}

// function signUpForGroup(params) {
//   return api.post("/users/registerForGroup", params);
// }

// function signUpWithAffiliateCode(params) {
//   return api.post("/users/register/affiliateCode", params);
// }

function refreshToken(params) {
  return api.post("/users/refresh", params);
}

function validateEmail(params) {
  return publicApi.post("/users/email/validate", params);
}

function validateUsername(params) {
  return publicApi.post("/users/username/validate", params);
}

function logInDiscordUser(params) {
  return publicApi.get("/discord/login", { params });
}

export default {
  logIn,
  logOut,
  signUp,
  refreshToken,
  validateUsername,
  validateEmail,
  logInDiscordUser
};
