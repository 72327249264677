import api from "./client";

const fetchGroupPosts = (groupId, params) =>
  api.get(`/groups/${groupId}/posts`, { params });

const createGroupPost = (groupId, params) =>
  api.post(`groups/${groupId}/posts/add`, params);

export default {
  fetchGroupPosts,
  createGroupPost
};
