import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import StartupActions from "app/state/redux/startup_redux";
import AuthActions from "app/state/redux/auth_redux";
import CurrentUserActions from "app/state/redux/current_user_redux";
import WaitForStartup from "app/containers/wait_for_startup";
import AppMessage from "app/containers/app_message";
import store from "app/state/create_store";
import initFirebase from "app/services/init_firebase";
import api from "app/services/api";
import { darkTheme } from "app/themes/theme";
import config from "app/config";
import router from "./router";
import "./styles.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-multi-carousel/lib/styles.css";

initFirebase();

function App() {
  useEffect(() => {
    store.dispatch(StartupActions.startup());
    document.title = config.BRAND_NAME;
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", `/${config.ASSET_PATH}/favicon.ico`);
  }, []);

  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      store.dispatch(AuthActions.setFirebaseUser(user.toJSON()));
      const idToken = await user.getIdToken();
      api.client.setAccessToken(idToken);
      store.dispatch(CurrentUserActions.fetchCurrentUser());
      store.dispatch(CurrentUserActions.subscribeToUserSocket(idToken));
    } else {
      store.dispatch(AuthActions.setFirebaseUser(null));
      api.client.setAccessToken();
    }
    store.dispatch(AuthActions.finishLoading());
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AppMessage />
        <WaitForStartup>
          <React.StrictMode>
            <RouterProvider router={router} />
          </React.StrictMode>
        </WaitForStartup>
      </ThemeProvider>
    </Provider>
  );
}

createRoot(document.getElementById("root")).render(
  <App />
);
