import { put, takeLatest } from "redux-saga/effects";
import authApiCall from "app/services/call_auth_api";
import api from "app/services/api";
import { sanitizeObjectIds } from "./helpers/json_models/helpers";
import CurrentUserActions, { CurrentUserTypes as types } from "../redux/current_user_redux";

function* fetchCurrentUser() {
  try {
    const response = yield authApiCall(api.getProfile);
    const user = sanitizeObjectIds(response.data);
    yield put(CurrentUserActions.fetchCurrentUserSuccess(user));
  } catch (e) {
    yield put(CurrentUserActions.fetchCurrentUserFailure());
  }
}

export default function currentUserSagas() {
  return [
    takeLatest(types.fetchCurrentUser, fetchCurrentUser)
  ];
}
