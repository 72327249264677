import { combineReducers } from "redux-immutable";
import { StartupReducer as startup } from "./startup_redux";
import { AuthReducer as auth } from "./auth_redux";
import { CurrentUserReducer as currentUser } from "./current_user_redux";
import { GroupsReducer as groups } from "./groups_redux";
import { PostsReducer as posts } from "./posts_redux";
import { ItemsReducer as items } from "./items_redux";
import { AppStateReducer as appState } from "./app_state_redux";
import { RegistrationReducer as registration } from "./registration_redux";
import { CommunityInfoReducer as communityInfo } from "./community_info_redux";
import { EventsReducer as events } from "./events_redux";

export default combineReducers({
  startup,
  auth,
  currentUser,
  groups,
  posts,
  items,
  appState,
  registration,
  communityInfo,
  events
});
