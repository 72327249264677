import { createTheme } from "@mui/material/styles";
import Colors, { lightColors, darkColors } from "./colors";

const immutableTheme = createTheme({
  palette: {
    primary: {
      main: Colors.brandColor,
      contrastText: Colors.contrastText
    },
    secondary: {
      main: Colors.lightBlue,
      contrastText: Colors.white
    },
    text: {
      link: Colors.linkText
    },
    white: {
      main: Colors.white,
      contrastText: Colors.black
    },
    facebook: {
      main: Colors.facebookBlue,
      contrastText: Colors.white
    },
    discord: {
      main: Colors.discordBlue,
      contrastText: Colors.white
    }
  },
  disabledPrimaryButton: {
    backgroundColor: Colors.withOpacity(Colors.brandColor, "99"),
    color: Colors.withOpacity(Colors.contrastText, "99")
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  }
});

export default createTheme(immutableTheme, {
  palette: {
    text: {
      main: lightColors.mainText,
      primary: lightColors.mainText,
      light: lightColors.lightText,
      secondary: lightColors.lightText
    },
    background: {
      default: lightColors.background,
      alternate: lightColors.backgroundMedium
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: lightColors.backgroundMedium,
          color: lightColors.mainText
        }
      }
    },
    MuiSvgIcon: {
      defaultProps: {
        color: lightColors.mainText,
        htmlColor: lightColors.mainText
      }
    },
    MuiAppBar: {
      defaultProps: {
        color: "default"
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: lightColors.background,
          backgroundImage: "none"
        }
      }
    }
  }
});

export const darkTheme = createTheme(immutableTheme, {
  palette: {
    mode: "dark",
    text: {
      main: darkColors.mainText,
      primary: darkColors.mainText,
      light: darkColors.lightText,
      secondary: darkColors.lightText
    },
    background: {
      default: darkColors.background,
      alternate: darkColors.backgroundMedium
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: darkColors.backgroundMedium,
          // Removes the linear gradient automatically added to mobile drawer
          backgroundImage: "none",
          color: darkColors.mainText
        }
      }
    },
    MuiSvgIcon: {
      defaultProps: {
        color: darkColors.mainText,
        htmlColor: darkColors.mainText
      }
    },
    MuiAppBar: {
      defaultProps: {
        color: "default"
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: darkColors.background,
          backgroundImage: "none"
        }
      }
    },
  }
});
