import api from "./client";

const fetchUserUpcomingEvents = userId =>
  api.get(`/users/${userId}/events/upcoming`);

const fetchEventDetails = (groupId, eventId) =>
  api.get(`/groups/${groupId}/events/${eventId}/details`);

export default {
  fetchUserUpcomingEvents,
  fetchEventDetails
};
