import { put, takeLatest } from "redux-saga/effects";
import api from "app/services/api";
import authApiCall from "app/services/call_auth_api";
import EventsActions, { EventsTypes } from "app/state/redux/events_redux";
import { sanitizeObjectIds } from "./helpers/json_models/helpers";

function normalizeEvents(data) {
  const normalized = {};
  const ordered = [];
  data.forEach((event) => {
    // eslint-disable-next-line no-param-reassign
    event.id = event._id;
    normalized[event.id] = sanitizeObjectIds(event);
    ordered.push(event.id);
  });
  return { normalized, ordered };
}

function* fetchUserUpcomingEvents() {
  try {
    const response = yield authApiCall(api.fetchUserUpcomingEvents);
    const data = normalizeEvents(response.data);
    yield put(EventsActions.fetchUserUpcomingEventsSuccess(data));
  } catch (err) {
    yield put(EventsActions.fetchUserUpcomingEventsFailure());
  }
}

function* fetchEventDetails(action) {
  try {
    const eventId = action.data;
    const response = yield authApiCall(api.fetchEventDetails, null, eventId);

    const { data } = response.data;
    const event = sanitizeObjectIds(data);
    yield put(EventsActions.fetchEventDetailsSuccess(event));
  } catch (err) {
    yield put(EventsActions.fetchEventDetailsFailure());
  }
}

export default function eventsSagas() {
  return [
    takeLatest(EventsTypes.fetchUserUpcomingEvents, fetchUserUpcomingEvents),
    takeLatest(EventsTypes.fetchEventDetails, fetchEventDetails)
  ];
}
