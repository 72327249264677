import router from "../router";

// Navigating outside of React components is not a straightforward problem to
// solve with React-Router, and does not have a stable solution. The official
// suggestion of the React-Router team if you're using a Data Router in v6.4+ is
// to simply use the 'navigate' method on the Router object itself:
// https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
export default {
  toHomePage() {
    router.navigate("/");
  },
  toLoginPage() {
    router.navigate("/login");
  },
  toRegisterPage() {
    router.navigate("/register");
  },
  toRegisterSocialSection() {
    router.navigate("/register/social");
  },
  toRegisterEmailSection() {
    router.navigate("/register/email");
  },
  toAppStorePage() {
    router.navigate("/app");
  },
  reloadHomePage() {
    window.location = "/";
  }
};
