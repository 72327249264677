export const getRspErrMessage = (rsp) => {
  if (rsp.data) {
    return rsp.data.err || JSON.stringify(rsp.data);
  }
  return "Unknown error, please retry";
};

export const getErrorMessage = (any) => {
  if (!any) {
    return "Unknown error, please retry";
  }
  if (typeof any === "string") {
    return any;
  }
  if (typeof any === "object") {
    const obj = any;
    // Standard error with message field
    if (obj.message) {
      return obj.message;
    }
    // Axios request error with response field,
    // or standard response with error message
    const rsp = obj.data ? obj : obj.response;
    if (rsp) {
      return getRspErrMessage(rsp);
    }
  }
  return JSON.stringify(any);
};
