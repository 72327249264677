import { call, put, takeLatest } from "redux-saga/effects";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  OAuthProvider,
  signInWithCustomToken
} from "firebase/auth";
import config from "app/config";
import api from "app/services/api";
import NavigationService from "app/services/navigation_service";
import { getErrorMessage } from "./helpers/errors";
import AuthActions, { AuthTypes as types } from "../redux/auth_redux";
import RegistrationActions from "../redux/registration_redux";

const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const appleAuthProvider = new OAuthProvider("apple.com");

async function signInWithGoogle() {
  const auth = getAuth();
  await signInWithPopup(auth, googleAuthProvider);
}

async function signInWithFacebook() {
  const auth = getAuth();
  await signInWithPopup(auth, facebookAuthProvider);
}

async function signInWithApple() {
  const auth = getAuth();
  await signInWithPopup(auth, appleAuthProvider);
}

async function signInWithCustomAuthToken(customAuthToken) {
  const auth = getAuth();
  await signInWithCustomToken(auth, customAuthToken);
}

async function signInWithEmail(email, password) {
  try {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    /* If Firebase account associated with email exists but Auxxit account doesn't,
       then continue with registration (User claims unmatched Firebase account) */
    if (err.code !== "auth/user-not-found") {
      throw err;
    }
  }
}

async function signOut() {
  const auth = getAuth();
  await auth.signOut();
}

function* attemptGoogleLogIn() {
  try {
    yield call(signInWithGoogle);
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(AuthActions.attemptLogInFailure(err));
  }
}

function* attemptFacebookLogIn() {
  try {
    yield call(signInWithFacebook);
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(AuthActions.attemptLogInFailure(err));
  }
}

function* attemptAppleLogIn() {
  try {
    yield call(signInWithApple);
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(AuthActions.attemptLogInFailure(err));
  }
}

function* attemptDiscordLogIn() {
  try {
    window.location.href = config.DISCORD_URL;
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(AuthActions.attemptLogInFailure(err));
  }
}

function* logInDiscordUser(action) {
  try {
    const { code } = action;
    const response = yield call(api.logInDiscordUser, { code });
    if (response.data.customAuthToken) {
      yield call(signInWithCustomAuthToken, response.data.customAuthToken);
      // If Discord user is included, we need to register
      if (response.data.discordUser) {
        yield put(RegistrationActions.setDiscordUser(response.data.discordUser));
        yield call(NavigationService.toRegisterSocialSection);
      }
    } else {
      throw new Error(response.data.err || "Failed to log in with Discord");
    }
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(AuthActions.attemptLogInFailure(err));
    yield call(NavigationService.toLoginPage);
  }
}

function* attemptEmailPasswordLogIn(action) {
  try {
    const { email: formEmail, password1, username } = action.data;
    yield call(signInWithEmail, formEmail, password1, username);
    yield put(AuthActions.attemptLoginSuccess(action.data));
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(AuthActions.attemptLogInFailure(err));
  }
}

function* attemptLogOut() {
  yield call(signOut);
}

export default function authSagas() {
  return [
    takeLatest(types.attemptEmailPasswordLogIn, attemptEmailPasswordLogIn),
    takeLatest(types.attemptGoogleLogIn, attemptGoogleLogIn),
    takeLatest(types.attemptFacebookLogIn, attemptFacebookLogIn),
    takeLatest(types.attemptAppleLogIn, attemptAppleLogIn),
    takeLatest(types.attemptDiscordLogIn, attemptDiscordLogIn),
    takeLatest(types.logInDiscordUser, logInDiscordUser),
    takeLatest(types.attemptLogOut, attemptLogOut)
  ];
}
