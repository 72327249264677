const styles = {
  title: {
    marginBottom: 10
  },
  passwordValidationText: {
    display: "flex"
  },
  socialLogo: {
    position: "absolute",
    left: 10
  },
  linkButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    textTransform: "none",
    fontSize: 16
  }
};

export default styles;
