import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { getAuth } from "firebase/auth";

import ProtectedRoute from "./containers/protected_route";
import HomePage from "./pages/home_page";
import RegistrationPage from "./pages/registration_page";
import RegistrationSocialSection from "./pages/registration_page/components/social_section";
import RegistrationEmailSection from "./pages/registration_page/components/email_section";
import RegistrationStartSection from "./pages/registration_page/components/start_section";
import LoginPage from "./pages/login_page";
import LoginEmailSection from "./pages/login_page/components/email_section";
import LoginStartSection from "./pages/login_page/components/start_section";
import FeaturedPage from "./pages/featured_page";
import AppStorePage from "./pages/app_store_page";
import GroupLandingPage from "./pages/group_landing_page";
import EventLandingPage from "./pages/event_landing_page";
import ItemLandingPage from "./pages/item_landing_page";
import EventPage from "./pages/event_page";
import EventLivePage from "./pages/event_live_page";
import ItemPage from "./pages/item_page";
import ItemBidPage from "./pages/item_bid_page";
import ErrorPage from "./pages/error_page";
import DiscordRedirectPage from "./pages/discord_redirect_page";

export default createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
    loader() {
      try {
        const auth = getAuth();
        return { user: auth.currentUser };
      } catch (err) {
        return null;
      }
    },
    errorElement: <ErrorPage />
  },
  {
    path: "e/:eventId",
    element: (
      <ProtectedRoute>
        <EventPage />
      </ProtectedRoute>
    )
  },
  {
    path: "e/:eventId/live",
    element: (
      <ProtectedRoute>
        <EventLivePage />
      </ProtectedRoute>
    )
  },
  {
    path: "e/:eventId/items/new",
    element: (
      <ProtectedRoute />
    )
  },
  {
    path: "i/:itemId",
    element: (
      <ProtectedRoute>
        <ItemPage />
      </ProtectedRoute>
    )
  },
  {
    path: "i/:itemId/bid",
    element: (
      <ProtectedRoute>
        <ItemBidPage />
      </ProtectedRoute>
    )
  },
  {
    path: "register",
    element: <RegistrationPage />,
    children: [
      {
        path: "",
        element: <RegistrationStartSection />
      },
      {
        path: "social",
        element: <RegistrationSocialSection />
      },
      {
        path: "email",
        element: <RegistrationEmailSection />
      }
    ]
  },
  {
    path: "/login",
    element: <LoginPage />,
    children: [
      {
        path: "",
        element: <LoginStartSection />
      },
      {
        path: "email",
        element: <LoginEmailSection />
      },
      {
        path: "discord/redirect",
        element: <DiscordRedirectPage />
      }
    ]
  },
  {
    path: "featured",
    element: <FeaturedPage />
  },
  {
    path: "app",
    element: <AppStorePage />
  },
  {
    path: "c/:groupId",
    element: <GroupLandingPage />
  },
  {
    path: "c/:groupId/events/:eventId",
    element: <EventLandingPage />
  },
  {
    path: "c/:groupId/items/:itemId",
    element: <ItemLandingPage />
  }
]);
