import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Carousel from "react-multi-carousel";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Avatar
} from "@mui/material";
import config from "app/config";
import useBreakpoints from "app/services/use_breakpoints";
import { formatPrice } from "app/services/text_format";
import EventsActions, { EventsSelectors } from "app/state/redux/events_redux";
import ItemsActions, { ItemsSelectors } from "app/state/redux/items_redux";
import GroupsActions from "app/state/redux/groups_redux";
import ItemCardSimple from "app/containers/item_card_simple";
import ResponsivePage from "../layouts/responsive_page";

const { EVENT_TYPE } = config;

const EventLivePage = function EventLivePage() {
  const { eventId } = useParams();

  const eventDetails = useSelector(
    state => EventsSelectors.getEvent(state, eventId)
  ) || {};

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(EventsActions.fetchEventDetails(eventId));
  }, []);

  useEffect(() => {
    if (eventDetails.type) {
      dispatch(ItemsActions.fetchEventItems({ eventId, eventType: eventDetails.type }));
    }
  }, [eventDetails.type]);

  useEffect(() => {
    if (eventDetails.groupId) {
      dispatch(GroupsActions.subscribeToGroupSocket(eventDetails.groupId));
    }
  }, [eventDetails.groupId]);

  const items = useSelector(state => ItemsSelectors.getEventItems(state)) || [];
  const eventBids = useSelector(state => ItemsSelectors.getEventItemBids(state)) || [];

  const { type } = eventDetails;

  const theme = useTheme();
  const breakpoints = useBreakpoints();

  const { breakpoints: { values: breakpointValues } } = theme;
  const responsive = {
    xl: {
      breakpoint: { max: 3000, min: breakpointValues.xl },
      items: 8,
    },
    lg: {
      breakpoint: { max: 3000, min: breakpointValues.lg },
      items: 6,
    },
    md: {
      breakpoint: { max: breakpointValues.lg, min: breakpointValues.md },
      items: 4,
    },
    sm: {
      breakpoint: { max: breakpointValues.md, min: breakpointValues.sm },
      items: 2,
    },
    xs: {
      breakpoint: { max: breakpointValues.sm, min: 0 },
      items: 1,
    }
  };

  const breakpoint = breakpoints.getBreakPointName();

  const isAuction = type === EVENT_TYPE.AUCTION;

  return (
    <ResponsivePage title="We're Live!">
      <Grid sx={{ mt: 1, mb: 1 }}>
        <Carousel
          swipeable
          showDots
          centerMode
          responsive={responsive}
          ssr={false}
          infinite
          autoPlay
          autoPlaySpeed={5000}
          keyBoardControl
          transitionDuration={500}
          removeArrowOnDeviceType={["xs", "sm"]}
          deviceType={breakpoint}
        >
          {items.map(itemId => (
            <ItemCardSimple
              key={itemId}
              id={itemId}
            />
          ))}
        </Carousel>
      </Grid>
      <Grid sx={{ mt: 1, mb: 1 }}>
        <Typography variant="h6" color="text.main" fontWeight="bold" sx={{ mb: 1 }}>
          {`${isAuction ? "Bids" : "Offers"}`}
        </Typography>
        {eventBids.map(({ id, amount, user, itemName, entryDate }) => (
          <Card
            key={id}
            sx={{
              display: "flex",
              bgcolor: "background.alternate",
              backgroundImage: "none",
              borderRadius: 0,
              pl: 2,
              pr: 2,
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt="avatar img"
                  src={user.avatar}
                  sx={{ width: 56, height: 56 }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ bgcolor: "background.alternate", backgroundImage: "none" }}>
                  <Typography gutterBottom variant="subtitle" component="div">
                    {itemName}
                  </Typography>
                  <Typography variant="h6" color="text.secondary" fontWeight="bold">
                    {formatPrice(amount)}
                  </Typography>
                </CardContent>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="caption" color="text.secondary">
                {new Date(entryDate).toLocaleDateString()}
              </Typography>
            </Box>
          </Card>
        ))}
      </Grid>
    </ResponsivePage>
  );
};

export default EventLivePage;
