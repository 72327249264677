import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea
} from "@mui/material";

function CardSimple(props) {
  const { name, image, route } = props;
  const navigate = useNavigate();
  return (
    <Card elevation={0} sx={{ width: 120, ml: 1, mr: 1, bgcolor: "transparent", backgroundImage: "none" }}>
      <CardActionArea onClick={() => navigate(route)}>
        <CardMedia
          component="img"
          height="100"
          width="100"
          image={image}
          alt="Card image!"
        />
        <CardContent
          sx={{
            bgcolor: "transparent",
            backgroundImage: "none",
            pl: 0,
            pt: 1
          }}
        >
          <Typography variant="body2" color="text.main">
            {name || "Upcoming Event"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CardSimple.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  route: PropTypes.string
};

CardSimple.defaultProps = {
  image: null,
  name: "Unknown",
  route: "/"
};

export default CardSimple;
