import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  Stack,
  Grid,
  Button,
  Typography,
  Link,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RegistrationActions from "app/state/redux/registration_redux";
import { darkTheme } from "app/themes/theme";
import config from "app/config";
import styles from "../styles";

function EmailFormSection(props) {
  const { group, affiliateCode } = useOutletContext();
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const { attemptEmailPasswordRegister } = props;

  const handlePolicyAcceptedChange = e => setPolicyAccepted(e.currentTarget.checked);
  const handleUsernameChange = e => setUsername(e.currentTarget.value);
  const handleEmailChange = e => setEmail(e.currentTarget.value);
  const handlePassword1Change = e => setPassword1(e.currentTarget.value);
  const handlePassword2Change = e => setPassword2(e.currentTarget.value);

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    const groupId = group ? group.id : null;
    const groupName = group ? group.name : null;
    const groupIcon = group ? group.icon : null;

    attemptEmailPasswordRegister({
      username,
      email,
      password1,
      password2,
      policyAccepted,
      groupId,
      affiliateCode,
      groupName,
      groupIcon,
    });
  };

  const comparePassword = () => {
    if (
      password1 !== undefined
      && password2 !== undefined
      && password1 === password2
      && password1 !== ""
    ) {
      return true;
    }
    return false;
  };

  // Check the length of the input
  const checkPasswordLength = () => {
    if (password1.length >= 8 && password1.length <= 30) {
      return true;
    }
    return false;
  };

  // Check for special characters
  const checkSpecialCharacters = () => {
    const pattern = /[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g;
    if (pattern.test(password1)) {
      return true;
    }
    return false;
  };

  // Check for an uppercase character
  const checkUppercase = () => {
    const pattern = /[A-Z]/;
    if (pattern.test(password1)) {
      return true;
    }
    return false;
  };

  // Check for a number
  const checkNumber = () => {
    const pattern = /[0-9]/;
    if (pattern.test(password1)) {
      return true;
    }
    return false;
  };

  const checkPasswordRequirements = () => {
    if (checkPasswordLength()
    && checkSpecialCharacters()
    && checkUppercase()
    && checkNumber()) {
      return true;
    }
    return false;
  };

  const validateForm = () => {
    if (
      checkPasswordRequirements()
      && comparePassword()
      && policyAccepted
      && email
      && username
    ) {
      return true;
    }
    return false;
  };

  return (
    <Stack alignSelf="stretch">
      <Typography variant="h6" color="primary" align="center" fontWeight="bold" sx={{ mt: 10, mb: 2 }}>
        Sign-Up
      </Typography>
      <TextField
        variant="outlined"
        label="Email"
        value={email}
        fullWidth
        autoFocus
        sx={{ mt: 1, mb: 1 }}
        color="primary"
        onChange={handleEmailChange}
      />
      <TextField
        variant="outlined"
        label="Username"
        value={username}
        fullWidth
        sx={{ mt: 1, mb: 1 }}
        color="primary"
        onChange={handleUsernameChange}
      />
      <TextField
        variant="outlined"
        type="password"
        label="Password"
        value={password1}
        fullWidth
        sx={{ mt: 1, mb: 1 }}
        color="primary"
        onChange={handlePassword1Change}
      />
      <TextField
        variant="outlined"
        type="password"
        label="Confirm password"
        value={password2}
        fullWidth
        sx={{ mt: 1, mb: 1 }}
        color="primary"
        onChange={handlePassword2Change}
      />
      <Grid
        container
        direction="column"
        alignItems="flex-start"
      >
        <Typography sx={styles.passwordValidationText} color="text.main">
          {comparePassword() ? <CheckIcon color="secondary" /> : <CloseIcon color="warning" />}
          Passwords matched
        </Typography>
        <Typography sx={styles.passwordValidationText} color="text.main">
          {checkPasswordLength() ? <CheckIcon color="secondary" /> : <CloseIcon color="warning" />}
          8-30 characters
        </Typography>
        <Typography sx={styles.passwordValidationText} color="text.main">
          {checkSpecialCharacters() ? <CheckIcon color="secondary" /> : <CloseIcon color="warning" />}
          1 special character
        </Typography>
        <Typography sx={styles.passwordValidationText} color="text.main">
          {checkUppercase() ? <CheckIcon color="secondary" /> : <CloseIcon color="warning" />}
          1 uppercase letter
        </Typography>
        <Typography sx={styles.passwordValidationText} color="text.main">
          {checkNumber() ? <CheckIcon color="secondary" /> : <CloseIcon color="warning" />}
          1 number
        </Typography>
      </Grid>
      <FormControlLabel
        sx={{ mt: 1, mb: 1 }}
        control={(
          <Checkbox
            checked={policyAccepted}
            onChange={handlePolicyAcceptedChange}
            color="secondary"
            name="policyAccepted"
          />
        )}
        label={(
          <Typography color="text.main">
            <span>{`By checking, you agree to ${config.BRAND_NAME}'s `}</span>
            <Link
              href={`${config.WEBSITE_URL}/user-agreements`}
              target="_blank"
              rel="noopener noreferrer"
              color="text.link"
            >
              User Agreement and all other policies
            </Link>
            <span>.</span>
          </Typography>
        )}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={!validateForm()}
        size="large"
        color="primary"
        sx={{ "&.Mui-disabled": darkTheme.disabledPrimaryButton, mt: 8 }}
        onClick={handleRegisterSubmit}
        disableElevation
      >
        Register
        <ArrowForwardIcon sx={{ marginLeft: 1 }} />
      </Button>
    </Stack>
  );
}

EmailFormSection.propTypes = {
  attemptEmailPasswordRegister: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  attemptEmailPasswordRegister: registerData =>
    dispatch(RegistrationActions.attemptEmailPasswordRegister(registerData)),
});

export default connect(null, mapDispatchToProps)(EmailFormSection);
