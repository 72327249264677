import { eventChannel } from "redux-saga";
import {
  fork,
  take,
  all,
  takeLatest,
  call,
  put,
  cancelled,
} from "redux-saga/effects";

import listeners, { socket as socketClient } from "app/services/socket";

import GroupsActions, { GroupsTypes } from "../redux/groups_redux";
import { CurrentUserTypes } from "../redux/current_user_redux";
import AuthActions from "../redux/auth_redux";
import ItemsActions from "../redux/items_redux";

const GROUP_ROOM_TYPE = "group";

function* userSocketEventChannel(socket, authToken) {
  yield call(socket.connect, authToken);

  return eventChannel((emit) => {
    listeners.onTest((data) => {
      console.log("Test received:", data); // eslint-disable-line
    });

    listeners.onError((err) => {
      if (err === "TOKEN_EXPIRED") {
        emit(AuthActions.tokenExpiredReceived());
      }
      if (err === "INVALID_USER_ID" || err === "NO_ACCESS_TOKEN") {
        emit(AuthActions.attemptLogOut());
      }
    });

    listeners.onConnect(() => {
    });

    listeners.onDisconnect(() => {
    });

    listeners.onReconnectAttempt(() => {
    });

    listeners.onBid((bid) => {
      emit(ItemsActions.newBidReceived(bid));
    });

    return () => listeners.removeUserSocketListeners();
  });
}

function groupSocketEventChannel(socket, groupId) {
  return eventChannel(() => {
    listeners.joinGroupSocket(groupId);

    return () => listeners.removeGroupSocketListeners();
  });
}

function* handleSocketEvents(socketChannel) {
  try {
    while (true) {
      const action = yield take(socketChannel);
      yield put(action);
    }
  } finally {
    // In case of channel has ended.
    if (yield cancelled()) yield call(socketChannel.close);
  }
}

function* subscribeToUserSocket(action) {
  const authToken = action.data;
  const socketChannel = yield call(
    userSocketEventChannel,
    socketClient,
    authToken
  );
  yield fork(handleSocketEvents, socketChannel);
}

function* subscribeToGroupSocket(action) {
  const groupId = action.data;
  const socketChannel = yield call(
    groupSocketEventChannel,
    socketClient,
    groupId
  );
  yield fork(handleSocketEvents, socketChannel);
}

function* subscribeToSocketRooms() {
  yield all(
    socketClient.getRooms().map((r) => { // eslint-disable-line
      if (r.type === GROUP_ROOM_TYPE) {
        return put(GroupsActions.subscribeToGroupSocket(r.id));
      }
    })
  );
}

export default function socketSagas() {
  return [
    takeLatest(GroupsTypes.subscribeToGroupSocket, subscribeToGroupSocket),
    takeLatest(CurrentUserTypes.subscribeToUserSocket, subscribeToUserSocket),
    takeLatest(CurrentUserTypes.subscribeToSocketRooms, subscribeToSocketRooms),
  ];
}
