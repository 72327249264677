import { Map } from "immutable";

const INIT_STATE = Map({
  isRegistering: false,
  isSubmitting: false,
  registrationMessage: "",
  discordUser: null
});

const types = {
  validateEmail: "VALIDATE_EMAIL",
  validateEmailSuccess: "VALIDATE_EMAIL_SUCCESS",
  validateEmailFailure: "VALIDATE_EMAIL_FAILURE",
  attemptRegister: "ATTEMPT_REGISTER",
  attemptEmailPasswordRegister: "ATTEMPT_EMAIL_PASSWORD_REGISTER",
  attemptGoogleRegister: "ATTEMPT_GOOGLE_REGISTER",
  attemptFacebookRegister: "ATTEMPT_FACEBOOK_REGISTER",
  attemptAppleRegister: "ATTEMPT_APPLE_REGISTER",
  attemptDiscordRegister: "ATTEMPT_DISCORD_REGISTER",
  registerSuccess: "REGISTER_SUCCESS",
  registerFailure: "REGISTER_FAILURE",
  setRegistrationMessage: "SET_REGISTRATION_MESSAGE",
  clearRegistrationMessage: "CLEAR_REGISTRATION_MESSAGE",
  setDiscordUser: "SET_DISCORD_USER"
};

/* ********* Actions ********* */

const actions = {
  validateEmail(data) {
    return {
      type: types.validateEmail,
      data
    };
  },
  validateEmailSuccess(data) {
    return {
      type: types.validateEmailSuccess,
      data
    };
  },
  validateEmailFailure(data) {
    return {
      type: types.validateEmailFailure,
      data
    };
  },
  attemptRegister(data) {
    return {
      type: types.attemptRegister,
      data
    };
  },
  attemptEmailPasswordRegister(data) {
    return {
      type: types.attemptEmailPasswordRegister,
      data
    };
  },
  attemptGoogleRegister() {
    return {
      type: types.attemptGoogleRegister
    };
  },
  attemptFacebookRegister() {
    return {
      type: types.attemptFacebookRegister
    };
  },
  attemptAppleRegister() {
    return {
      type: types.attemptAppleRegister
    };
  },
  attemptDiscordRegister() {
    return {
      type: types.attemptDiscordRegister
    };
  },
  registerSuccess(data) {
    return {
      type: types.registerSuccess,
      data
    };
  },
  registerFailure(data) {
    return {
      type: types.registerFailure,
      data
    };
  },
  setRegistrationMessage(data) {
    return {
      type: types.setRegistrationMessage,
      data
    };
  },
  setDiscordUser(data) {
    return {
      type: types.setDiscordUser,
      data
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.validateEmail:
    return state.merge({ isSubmitting: true });
  case types.validateEmailSuccess:
    return state.merge({ isSubmitting: false, registrationMessage: "" });
  case types.validateEmailFailure:
    return state.merge({
      isSubmitting: false,
      registrationMessage: action.data.message || JSON.stringify(action.data)
    });
  case types.attemptRegister:
    return state.merge({ isRegistering: true, registrationMessage: "" });
  case types.attemptEmailPasswordRegister:
    return state.merge({ isRegistering: true, registrationMessage: "" });
  case types.attemptFacebookRegister:
    return state.merge({ isRegistering: true, registrationMessage: "" });
  case types.attemptGoogleRegister:
    return state.merge({ isRegistering: true, registrationMessage: "" });
  case types.attemptAppleRegister:
    return state.merge({ isRegistering: true, registrationMessage: "" });
  case types.attemptDiscordRegister:
    return state.merge({ isRegistering: true, registrationMessage: "" });
  case types.registerSuccess:
    return state.merge({ isRegistering: false, discordUser: null });
  case types.registerFailure:
    return state.merge({
      isRegistering: false,
      registrationMessage: action.data.message || JSON.stringify(action.data)
    });
  case types.setRegistrationMessage:
    return state.merge({ registrationMessage: action.data });
  case types.clearRegistrationMessage:
    return state.merge({ registrationMessage: "" });
  case types.setDiscordUser:
    return state.merge({ discordUser: action.data });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const registration = state => state.get("registration");

const selectors = {
  getRegistrationMessage: state => registration(state).get("registrationMessage"),
  getDiscordUser: state => registration(state).get("discordUser")
};

export {
  types as RegistrationTypes,
  reducer as RegistrationReducer,
  selectors as RegistrationSelectors
};
export default actions;
