import { sanitizeObjectIds, sanitizeUser } from "./helpers";

function sanitizeCategories(categories) {
  if (!categories) {
    return [];
  }
  return categories.map(c => sanitizeObjectIds(c));
}

function sanitizeBooths(booths) {
  if (!booths) {
    return [];
  }
  return booths.map(b => sanitizeObjectIds(b));
}

function sanitizeMembers(data) {
  const members = [];
  Object.keys(data.attributes).forEach((memberType) => {
    const users = data.attributes[memberType].map(({ user, ...attributes }) => {
      const memberUser = sanitizeUser(user || attributes);
      return {
        ...memberUser,
        online: attributes.online,
        type: memberType,
      };
    });
    members.push({ type: memberType, data: users });
  });
  return members;
}

function sanitizeEvents(events) {
  if (!events) {
    return [];
  }
  return events.map(b => sanitizeObjectIds(b));
}

function sanitizeExpoEvents(data) {
  if (!data.data) {
    return [];
  }
  return data.data.map(e => sanitizeObjectIds(e));
}

function sanitizeBooth(data) {
  const booth = sanitizeObjectIds(data.data);
  return {
    ...booth,
    categories: booth.categories || []
  };
}

function sanitizeGroup(data) {
  const { attributes, id } = data;
  return {
    ...attributes,
    id,
    parentGroup: sanitizeObjectIds(attributes.parentGroup),
    categories: sanitizeCategories(attributes.categories),
    booths: sanitizeBooths(attributes.booths),
    expoEvents: sanitizeEvents(attributes.expoEvents)
  };
}

function sanitizeGroupDocument(data) {
  return sanitizeObjectIds(data);
}

function sanitizeGroups(data) {
  return data.data.map(group => sanitizeGroup(group));
}

function sanitizeFeaturedGroups(data) {
  return data.map(g => sanitizeObjectIds(g));
}

export default {
  sanitizeCategories,
  sanitizeMembers,
  sanitizeGroup,
  sanitizeGroupDocument,
  sanitizeGroups,
  sanitizeFeaturedGroups,
  sanitizeBooth,
  sanitizeBooths,
  sanitizeExpoEvents,
  sanitizeEvents
};
