import { put, takeLatest, call } from "redux-saga/effects";
import api from "app/services/api";
/* eslint-disable import/no-named-as-default */
import callAuthApi from "app/services/call_auth_api";
import GroupsActions, { GroupsTypes } from "app/state/redux/groups_redux";
import ModelHelper from "./helpers/json_models";

function* fetchGroups() {
  try {
    const response = yield callAuthApi(api.fetchMyGroups);

    const groups = ModelHelper.sanitizeGroups(response.data);
    yield put(GroupsActions.fetchGroupsSuccess(groups));
  } catch (e) {
    yield put(GroupsActions.fetchGroupsFailure());
  }
}

function* fetchFeaturedGroups() {
  try {
    const response = yield call(api.fetchFeaturedGroups);

    const groups = ModelHelper.sanitizeFeaturedGroups(response.data);
    yield put(GroupsActions.fetchGroupsSuccess(groups));
  } catch (e) {
    yield put(GroupsActions.fetchGroupsFailure());
  }
}

function* queryForGroup(action) {
  try {
    const { groupId, affiliateCode } = action.data;
    if (groupId) {
      // this will fail with 401 (Unauthorized) if client is not logged in
      yield put(GroupsActions.fetchGroup(groupId));
    } else if (affiliateCode) {
      const response = yield callAuthApi(api.fetchGroupByAffiliateCode, affiliateCode);
      const group = ModelHelper.sanitizeGroupDocument(response.data);
      yield put(GroupsActions.fetchGroupSuccess(group));
    } else {
      yield put(GroupsActions.fetchGroupFailure());
    }
  } catch (e) {
    yield put(GroupsActions.fetchGroupFailure());
  }
}

function* fetchGroup(action) {
  const id = action.data;
  try {
    const response = yield callAuthApi(api.fetchGroup, id);
    const { data } = response.data;
    const group = ModelHelper.sanitizeGroup(data);
    yield put(GroupsActions.fetchGroupSuccess(group));
  } catch (e) {
    yield put(GroupsActions.fetchGroupFailure());
  }
}

export default function groupsSagas() {
  return [
    takeLatest(GroupsTypes.fetchGroups, fetchGroups),
    takeLatest(GroupsTypes.fetchFeaturedGroups, fetchFeaturedGroups),
    takeLatest(GroupsTypes.fetchGroup, fetchGroup),
    takeLatest(GroupsTypes.queryForGroup, queryForGroup)
  ];
}
