import { io } from "socket.io-client";
import config from "app/config";

/**
 * @typedef {import('socket.io-client').Socket} Socket
 */

class SocketClient {
  /**
   * @type {Socket}
   */
  socket;

  rooms;

  constructor() {
    this.rooms = [];
  }

  connect = (accessToken) => {
    this.socket = io(config.SOCKET_URL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      query: { accessToken },
      extraHeaders: {
        Authorization: accessToken
      }
    });
  };

  setSocketAuthOpts = (accessToken) => {
    this.socket.io.opts.query = { accessToken };
    this.socket.io.opts.extraHeaders = { Authorization: accessToken };
  };

  reconnect = (accessToken) => {
    this.setSocketAuthOpts(accessToken);
    if (!this.socket) {
      return;
    }
    this.socket.connect();
  };

  disconnect = () => {
    if (!this.socket) {
      return;
    }
    this.socket.disconnect();
  };

  checkConnection = () => this.socket && this.socket.connected;

  /**
   * Adds room ID and type to rooms list if
   * it doesn't already exist.
   * @param {string} id   room ID
   * @param {string} type room type
   */
  joinRoom = (id, type, roomType) => {
    if (!Array.isArray(this.rooms)) {
      return;
    }
    const room = this.rooms.find(r => r.id === id && r.type === type);
    // if room already exists, don't add a duplicate
    if (this.rooms.indexOf(room) > -1) {
      return;
    }
    this.rooms.push({ id, type, roomType });
  };

  /**
   * Removes room given ID from rooms list.
   * @param {string} id   room ID
   */
  leaveRoom = (id, type) => {
    if (!Array.isArray(this.rooms)) {
      return;
    }
    const room = this.rooms.find(r => r.id === id && r.type === type);
    // if room doesn't exist, don't try to remove
    if (this.rooms.indexOf(room) === -1) {
      return;
    }
    this.rooms.splice(this.rooms.indexOf(room), 1);
  };

  getClient = () => this.socket;

  getRooms = () => this.rooms;
}

export default new SocketClient();
