import * as groupsSockets from "./groups_sockets";
import * as chatSockets from "./chat_sockets";
import * as userSockets from "./user_sockets";
import socketClient from "./client";

export const socket = socketClient;

export default {
  ...groupsSockets,
  ...chatSockets,
  ...userSockets
};
