import { publicClient as publicApi } from "./client";

const fetchGroupByAffiliateCode = affiliateCode => publicApi.get(`/groups/affiliateCode/${affiliateCode}`);

const fetchPublicGroupInfo = groupId => publicApi.get(`/groups/${groupId}/info`);

const fetchPublicEventItems = eventId => publicApi.get(`/events/${eventId}/items`);

export default {
  fetchGroupByAffiliateCode,
  fetchPublicGroupInfo,
  fetchPublicEventItems
};
