import { sanitizeObjectIds, sanitizeUser } from "./helpers";

function sanitizePosts(posts) {
  return posts.map((post) => {
    const { _id, createdBy, ...attributes } = post;
    return {
      ...sanitizeObjectIds(attributes),
      id: _id,
      createdBy: createdBy && sanitizeUser(createdBy)
    };
  });
}

function sanitizePostSocket(data) {
  return sanitizeObjectIds(data);
}

export default {
  sanitizePosts,
  sanitizePostSocket
};
