import socket from "./client";

export function onTest(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("test", eventHandler);
}

export function onError(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("error", eventHandler);
}

export function onConnect(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("connect", eventHandler);
}

export function onReconnectAttempt(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("reconnect_attempt", eventHandler);
}

export function onDisconnect(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("disconnect", eventHandler);
}

export function onBid(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("bid", eventHandler);
}

export function onAddOffer(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("add_offer", eventHandler);
}

export function onRemoveOffer(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("remove_offer", eventHandler);
}

export function onUpdateAskPrice(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("update_ask_price", eventHandler);
}

export function removeUserSocketListeners() {
  const socketClient = socket.getClient();
  socketClient.off("test");
  socketClient.off("error");
  socketClient.off("connect");
  socketClient.off("reconnect_attempt");
  socketClient.off("disconnect");
  socketClient.off("bid");
  socketClient.off("add_offer");
  socketClient.off("remove_offer");
  socketClient.off("update_ask_price");
}
