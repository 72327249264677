import { all } from "redux-saga/effects";

import startupSagas from "./startup_sagas";
import authSagas from "./auth_sagas";
import currentUserSagas from "./current_user_sagas";
import groupsSagas from "./groups_sagas";
import postsSagas from "./posts_sagas";
import socketSagas from "./socket_sagas";
import itemsSagas from "./items_sagas";
import registrationSagas from "./registration_sagas";
import communityInfoSagas from "./community_info_sagas";
import eventsSagas from "./events_sagas";

export default function* sagas() {
  yield all([
    ...startupSagas(),
    ...socketSagas(),
    ...authSagas(),
    ...currentUserSagas(),
    ...groupsSagas(),
    ...postsSagas(),
    ...itemsSagas(),
    ...registrationSagas(),
    ...communityInfoSagas(),
    ...eventsSagas()
  ]);
}
