export const getUserAvatar = avatar =>
  (avatar.includes("https:") ? avatar : `https:${avatar}`);

export const sanitizeUser = (user) => {
  const { username, avatar, email, _id: id, firstName, lastName, settings } = user;
  return { username, avatar: getUserAvatar(avatar), email, id, firstName, lastName, settings };
};

/**
 *
 * @param {object} object
 */
export const sanitizeObjectIds = (object) => {
  // if not object, return
  if (!object || typeof object !== "object") {
    return object;
  }

  const result = {};
  Object.keys(object).forEach((field) => {
    const data = object[field];
    if (Array.isArray(data)) {
      result[field] = data.map(el => sanitizeObjectIds(el));
    } else if (typeof data === "object") {
      result[field] = sanitizeObjectIds(data);
    } else if (
      typeof data === "string"
      && field === "_id"
    ) {
      result[field] = data;
      result.id = data;
    } else {
      result[field] = data;
    }
  });
  return result;
};
