import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Container,
  Button,
  Stack,
  Avatar,
  Card,
  CardMedia,
  CardContent,
  Paper
} from "@mui/material";
import { CommunityInfoSelectors } from "app/state/redux/community_info_redux";
import Page from "../layouts/grid_page";

const ItemDetailsPage = function ItemDetailsPage() {
  const { itemId } = useParams();
  const navigate = useNavigate();

  const itemDetails = useSelector(
    state => CommunityInfoSelectors.getItemDetails(state, itemId)
  ) || {};

  const { startPrice } = itemDetails;
  const price = startPrice % 1 === 0 ? startPrice?.toString() : startPrice?.toFixed(2);

  const isAuctionItem = itemDetails.type === "auction_item";

  let offers;
  if (isAuctionItem) {
    offers = itemDetails.lifeCycle?.bids;
  } else {
    offers = itemDetails.lifeCycle?.offers;
  }
  const latestAmount = offers?.length
    ? `$${offers[offers.length - 1].amount.toFixed(2)}`
    : "None";

  const { shipping } = itemDetails;
  const shippingCost = shipping?.cost ? `$${shipping.cost.toFixed(2)}` : "$0.00";

  const imgUrl = itemDetails.images ? itemDetails.images[0]?.urlMap?.large : null;

  return (
    <Page
      title="Item Details"
      contentAlign="center"
    >
      <Grid container sx={{ height: "100%" }}>
        <Card sx={{ width: "100%", bgcolor: "transparent", backgroundImage: "none" }}>
          <CardMedia
            component="img"
            alt="item icon"
            height={315}
            image={imgUrl}
          />
          <CardContent sx={{ bgcolor: "transparent", backgroundImage: "none" }}>
            <Typography variant="h6" color="text.main" fontWeight="bold">
              {itemDetails.name}
            </Typography>
            <Stack direction="row" sx={{ mt: 1 }}>
              <Avatar
                alt={itemDetails.createdBy?.username}
                src={itemDetails.createdBy?.avatar}
                sx={{ mr: 2, width: 56, height: 56 }}
              />
              <Stack>
                <Typography variant="h6" color="text.main">
                  {itemDetails.createdBy?.username}
                </Typography>
                <Typography variant="caption" color="text.light">
                  (4) 99%
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="h5" color="primary" fontWeight="bold" sx={{ mt: 3 }}>
              {`$ ${price}`}
            </Typography>
            <Stack direction="row" sx={{ mt: 2 }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 1, mr: 1, height: 42, flex: 1 }}
                onClick={() => navigate("/register")}
                disableElevation
              >
                {`${isAuctionItem ? "Make a Bid" : "Buy"}`}
              </Button>
              {!isAuctionItem ? (
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ ml: 1, mr: 1, height: 42, flex: 1 }}
                  onClick={() => navigate("/register")}
                  disableElevation
                >
                  Make Offer
                </Button>
              ) : null}
            </Stack>
            <Paper elevation={0} sx={{ bgcolor: "background.alternate", mt: 3, p: 2 }}>
              <Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ ml: 1, mr: 1 }}>
                  <Typography variant="subtitle1" color="text.main" fontWeight="bold">
                    {`Latest ${isAuctionItem ? "Bid" : "Offer"}`}
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {latestAmount}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ ml: 1, mr: 1 }}>
                  <Typography variant="subtitle1" color="text.main" fontWeight="bold">
                    Shipping
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {shippingCost}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ ml: 1, mr: 1 }}>
                  <Typography variant="subtitle1" color="text.main" fontWeight="bold">
                    Returns
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {itemDetails.returnsAccepted ? "Yes" : "No"}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
            <Paper elevation={0} sx={{ bgcolor: "background.alternate", mt: 3, p: 2 }}>
              <Typography variant="h6" color="text.main" fontWeight="bold" sx={{ mb: 1 }}>
                Description
              </Typography>
              <Typography variant="subtitle2" color="text.main">
                {itemDetails.description || "This item does not have a description."}
              </Typography>
            </Paper>
          </CardContent>
        </Card>
        <Container sx={{ m: 2, height: 42, alignSelf: "flex-end" }}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={() => navigate("/register")}
            disableElevation
          >
            Join
          </Button>
        </Container>
      </Grid>
    </Page>
  );
};

export default ItemDetailsPage;
