import config from "../config";

// NOTE: To get a proper name for a new color please use
//       http://chir.ag/projects/name-that-color/

export const darkColors = {
  theme: "light-content",
  background: "#09080a",
  backgroundMedium: "#1c1a21",
  tickerBackground: "#1c1a21",
  emptyImageBackground: "#1f242b",
  header: "#000000",
  lineBreak: "#2b2b2b",
  border: "#e8e8e8",
  mainText: "#e8e8e8",
  lightText: "#d6d6d6",
  mediumText: "#a8adb5",
  darkText: "#676767",
};

export const lightColors = {
  theme: "dark-content",
  background: "#FFFFFF",
  backgroundMedium: "#FFFFFF",
  tickerBackground: "#ededed",
  emptyImageBackground: "#c2c2c2",
  header: "#000000",
  lineBreak: "#d0d5db",
  border: "#929292",
  mainText: "#262626",
  lightText: "#444444",
  mediumText: "#757575",
  darkText: "#999999",
};

export default {
  errorText: "#ff0000",
  successText: "#009963",
  linkText: "#19b6ff",
  opaqueBackground: "#000000b3",
  opaqueBackgroundDarkest: "#000000ed",
  opaqueBackground99: "#00000099",
  opaqueBackgroundRed: "#ff0000bf",
  lightVerticalGradient: ["#87878700", "#525252e8", "#292929d9"],
  blackVerticalGradient: ["#00000000", "#000000e8", "#000000d9"],
  greyVerticalGradient: ["#00000000", "#000000e8", "#000000b3"],
  reverseVerticalGradient: ["#00000050", "#00000080", "#00000000"],

  black: "#000000",
  grey: "#454545",
  greyBlurred: "#454545CC",
  white: "#FFFFFF",
  offWhite: "#f9f9f9",
  transparent: "#00000000",
  darkTransparent: "rgba(0,0,0,.7)",
  mediumTransparent: "rgba(0,0,0,.8)",
  darkerTransparent: "rgba(0,0,0,.9)",
  lightTransparent: "rgba(0,0,0,.5)",

  // Auxxit Colors
  auxxitBlue: "#3e5a80",
  auxxitOrange: "#fc5707",
  auxxitSlateLightest: "#f5f6f9",
  auxxitSlateLighter: "#edeff2",
  auxxitSlate: "#dfe4eb",
  auxxitSlateMedium: "#989ca4",
  auxxitSlateDarker: "#bec4ce",
  auxxitSlateDarkest: "#72757b",
  kellieOrange: "#DA674A",

  chatBlue: "#78acff",

  // brand colors
  halfBacked: "#88C4CA",
  horizon: "#6d6d6d",
  william: "#e1e5ea",
  outerSpace: "#2A3E41",

  // Auxxit Colors
  brightNavy: "#3d4db8",
  greenCyan: "#77d5a9",
  offRed: "#e2acac",
  darkGreen: "#1b5145",
  robinBlueEgg: "#00bbd5",
  lightBlue: "#0ca1f5",
  lightBlueOpaque: "#0ea8ff70",
  fauxAzure: "#07FCF4",
  lagoonTeal: "#05B0AB",
  strongCyan: "#B04705",
  green: "#3ad65e",
  pink: "#FFCCCC",
  red: "#e30000",
  crimson: "#990000",
  yellow: "#F8C544",
  cornflowerBlue: "#F1F9FF",
  sushi: "#89c541",
  alertGreen: "#2ade81",
  slateGrey: "#acafb6",
  lightGrey: "#d4d5d6",
  lightestGrey: "#f0f0f0",
  emeraldGreen: "#50C878",
  mediumGrey: "#828282",
  burntSienna: "#EE5E68",
  astral: "#2E75B6",
  aquamarineBlue: "#70E2BC",
  buttonBlue: "#5D83C1",
  facebookBlue: "#1877F2",
  discordBlue: "#5865F2",

  brandColor: config.BRAND_COLOR,
  contrastText: config.CONTRAST_TEXT,

  withOpacity: (color, opacity) => color.slice(0, 7) + opacity,
};
