import { put, takeLatest, takeEvery } from "redux-saga/effects";
import config from "app/config";
import api from "app/services/api";
import authApiCall from "app/services/call_auth_api";
import ItemsActions, { ItemsTypes as types } from "../redux/items_redux";
import EventsActions from "../redux/events_redux";
import { sanitizeObjectIds } from "./helpers/json_models/helpers";

const { EVENT_TYPE } = config;

/**
 * Normalizes old item responses that store data in "attributes".
 */
function normalizeItemsAttributes(data) {
  const normalized = {};
  const ordered = [];
  data.forEach(({ attributes, id }) => {
    const item = sanitizeObjectIds({ ...attributes });
    item.id = item._id || id;
    item.imgUrls = item.images.map(img => img.urlMap);
    item.images = item.images.map(image => image.url);
    normalized[item.id] = item;
    ordered.push(item.id);
  });
  return { normalized, ordered };
}

function* fetchEventItems(action) {
  const { eventId, eventType } = action.data;
  try {
    let response;
    switch (eventType) {
    case EVENT_TYPE.AUCTION: {
      const auctionId = eventId;
      response = yield authApiCall(api.fetchAuctionItems, auctionId);
      break;
    }
    case EVENT_TYPE.MARKET: {
      const liveMarketId = eventId;
      response = yield authApiCall(api.fetchMarketItems, liveMarketId);
      break;
    }
    default:
      throw new Error("Invalid event type");
    }
    const { normalized, ordered } = normalizeItemsAttributes(response.data.data);
    yield put(ItemsActions.fetchEventItemsSuccess({ normalized, ordered }));
  } catch (err) {
    yield put(ItemsActions.fetchEventItemsFailure());
  }
}

function* createNewBid(action) {
  const { groupId, itemId, eventId, bid } = action.data;
  try {
    yield authApiCall(api.createBid, eventId, itemId, {
      groupId,
      amount: bid,
    });
  } catch (err) {
    console.error(err);
  }
}

function* newBidReceived(action) {
  const {
    itemId: bidOnItem,
    auctionId: bidAuctionId,
    bid: bidData,
    extension: extendedCloseDate
  } = action.data;
  yield put(ItemsActions.addBidToItem(
    bidOnItem,
    { ...bidData, entryDate: new Date(bidData.entryDate).toISOString() }
  ));
  if (extendedCloseDate) {
    yield put(EventsActions.extendEvent(bidAuctionId, extendedCloseDate));
  }
}

export default function itemsSagas() {
  return [
    takeLatest(types.fetchEventItems, fetchEventItems),
    takeLatest(types.createNewBid, createNewBid),
    takeEvery(types.newBidReceived, newBidReceived)
  ];
}
