import api from "./client";

const fetchMyGroups = (params = {}) =>
  api.get("/groups/my", { params });

const fetchFeaturedGroups = (params = {}) =>
  api.get("/groups/featured", { params });

const fetchGroup = groupId => api.get(`/groups/${groupId}`);

export default {
  fetchMyGroups,
  fetchFeaturedGroups,
  fetchGroup
};
