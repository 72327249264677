import usStates from "./us_states";

/**
 * Environmental variables are exposed to the app via
 * webpack's DefinePlugin functionality. In order to
 * avoid using globally defined variables in the app's
 * code, add them to the list of ESLint globals below
 * and define them in the exported object.
 */

const config = {
  /* Env */
  API_URL: window.API_URL,
  SOCKET_URL: window.SOCKET_URL,
  WEBSITE_URL: window.WEBSITE_URL,
  APP_LINK: window.APP_LINK,
  FIREBASE_CONFIG: JSON.parse(window.atob(window.FIREBASE_CONFIG_BASE64)),
  BRANCH_KEY: window.BRANCH_KEY,
  BRAND_COLOR: window.BRAND_COLOR,
  CONTRAST_TEXT: window.CONTRAST_TEXT,
  BRAND_NAME: window.BRAND_NAME,
  DISCORD_URL: window.DISCORD_URL,
  ASSET_PATH: window.ASSET_PATH,
  /* Socket connection states */
  CONN_STATES: {
    CONNECTED: "connected",
    DISCONNECTED: "disconnected",
    RECONNECTING: "reconnecting"
  },
  US_STATES: usStates,
  // App Store config
  playStoreUrl: window.PLAY_STORE_URL,
  iosStoreUrl: window.IOS_STORE_URL,
  EVENT_TYPE: {
    AUCTION: "auction_event",
    MARKET: "market_event"
  }
};

export default config;
