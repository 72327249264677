import { call } from "redux-saga/effects";
import refreshAuthToken from "./refresh_auth_token";

function* callAuthApi(apiFunction, ...payload) {
  try {
    const response = yield call(apiFunction, ...payload);
    return response;
  } catch (e) {
    const { response } = e;
    if (response.status !== 401) throw e;

    const isRefreshed = yield call(refreshAuthToken);
    if (!isRefreshed) return response;

    return yield call(apiFunction, ...payload);
  }
}

async function asyncCallAuthApi(apiFunction, ...payload) {
  const response = await apiFunction(...payload);
  if (response.status !== 401) return response;

  const isRefreshed = await refreshAuthToken();
  if (!isRefreshed) return response;

  return apiFunction(...payload);
}

export default callAuthApi;
export { asyncCallAuthApi as callAuthApi };
