import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isFetching: false,
  items: Map({
    byId: Map({}),
    eventItems: List([])
  })
});

const types = {
  fetchEventItems: "FETCH_EVENT_ITEMS",
  fetchEventItemsSuccess: "FETCH_EVENT_ITEMS_SUCCESS",
  fetchEventItemsFailure: "FETCH_EVENT_ITEMS_FAILURE",
  createNewBid: "CREATE_NEW_BID",
  newBidReceived: "NEW_BID_RECEIVED",
  addBidToItem: "ADD_BID_TO_ITEM"
};

/* ********* Actions ********* */

const actions = {
  fetchEventItems(data) {
    return {
      type: types.fetchEventItems,
      data
    };
  },
  fetchEventItemsSuccess(data) {
    return {
      type: types.fetchEventItemsSuccess,
      data
    };
  },
  fetchEventItemsFailure() {
    return {
      type: types.fetchEventItemsFailure
    };
  },
  createNewBid(itemId, groupId, eventId, bid) {
    return {
      type: types.createNewBid,
      data: { itemId, groupId, eventId, bid }
    };
  },
  newBidReceived(data) {
    return {
      type: types.newBidReceived,
      data
    };
  },
  addBidToItem(itemId, bidData) {
    return {
      type: types.addBidToItem,
      data: { itemId, bidData }
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchEventItems:
    return state.set("isFetching", true);
  case types.fetchEventItemsSuccess: {
    const itemsMap = state.getIn(["items", "byId"]);
    return state
      .merge({ isFetching: false })
      .setIn(["items", "byId"], itemsMap.merge(fromJS(action.data.normalized)))
      .setIn(["items", "eventItems"], List(action.data.ordered));
  }
  case types.fetchEventItemsFailure:
    return state.set("isFetching", false);
  case types.addBidToItem: {
    const itemBids = state.getIn(["items", "byId", action.data.itemId, "bids"]);
    return state.setIn(
      ["items", "byId", action.data.itemId, "bids"],
      [...itemBids, action.data.bidData]
    );
  }
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const items = state => state.get("items");

const selectors = {
  getIsFetching: state => items(state).get("isFetching"),
  getEventItems: memoize(state => items(state)
    .getIn(["items", "eventItems"])
    .toJS()),
  getEventItemBids: memoize((state) => {
    const eventItemIds = items(state)
      .getIn(["items", "eventItems"]);
    const eventItemBids = [];
    const eventItems = items(state).getIn(["items", "byId"]).toJS();
    Object.values(eventItems).forEach((item) => {
      if (eventItemIds.includes(item.id) && item.bids) {
        const itemBids = item.bids.map(bid => ({ ...bid, itemName: item.name }));
        eventItemBids.push(...itemBids);
      }
    });
    return eventItemBids;
  }),
  getItem: memoize((state, id) => items(state)
    .getIn(["items", "byId", id])?.toJS()),
};

export {
  types as ItemsTypes,
  reducer as ItemsReducer,
  selectors as ItemsSelectors
};
export default actions;
