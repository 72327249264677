import api from "./client";

const addAuctionItemToGroup = (groupId, params) =>
  api.post(`/groups/${groupId}/items/add`, params);

const addMarketItemToGroup = (groupId, params) =>
  api.post(`/groups/${groupId}/items/add/market`, params);

const fetchAuctionItems = (auctionId, params) =>
  api.get(`/auctions/${auctionId}/items/all`, { params });

const fetchMarketItems = (liveMarketId, params) =>
  api.get(`/markets/${liveMarketId}/items/all`, { params });

const createBid = (auctionId, itemId, params) =>
  api.post(`/auctions/${auctionId}/items/${itemId}/bids/add`, params);

export default {
  addAuctionItemToGroup,
  addMarketItemToGroup,
  fetchAuctionItems,
  fetchMarketItems,
  createBid
};
