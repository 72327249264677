import socket from "./client";

const GROUP_ROOM_TYPE = "group";

export function joinGroupSocket(groupId) {
  const socketClient = socket.getClient();
  socket.joinRoom(groupId, GROUP_ROOM_TYPE);
  socketClient.emit("join", { roomId: groupId, roomType: GROUP_ROOM_TYPE });
}

export function onMemberJoined(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("member-joined", eventHandler);
}

export function onMemberLeft(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("member-left", eventHandler);
}

export function onGroupPostAdded(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("post", eventHandler);
}

export function onGroupPostDeleted(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("remove-post", (eventHandler));
}

export function onGroupPostHidden(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("hide-post", (eventHandler));
}

export function onAuctionStart(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("auction-start", eventHandler);
}

export function onAuctionEnd(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("auction-end", eventHandler);
}

export function onMarketStart(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("market-start", eventHandler);
}

export function onMarketEnd(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("market-end", eventHandler);
}

export function onStreamStart(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("stream-start", eventHandler);
}

export function onStreamEnd(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("stream-end", eventHandler);
}

export function onGroupPostLiked(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("like-post", (eventHandler));
}

export function onGroupPostUnliked(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("unlike-post", (eventHandler));
}

export function removeGroupSocketListeners() {
  const socketClient = socket.getClient();
  socketClient.off("member-joined");
  socketClient.off("member-left");
  socketClient.off("post");
  socketClient.off("remove-post");
  socketClient.off("auction-start");
  socketClient.off("auction-end");
  socketClient.off("market-start");
  socketClient.off("market-end");
  socketClient.off("stream-start");
  socketClient.off("stream-end");
  socketClient.off("like-post");
  socketClient.off("unlike-post");
}

export function leaveGroupSocket(groupId) {
  const socketClient = socket.getClient();
  socket.leaveRoom(groupId, GROUP_ROOM_TYPE);
  socketClient.emit("leave", { roomId: groupId, roomType: GROUP_ROOM_TYPE });
  removeGroupSocketListeners();
}
