import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Stack
} from "@mui/material";
import ItemsActions, { ItemsSelectors } from "app/state/redux/items_redux";
import Page from "../layouts/grid_page";

const ItemDetailsPage = function ItemDetailsPage() {
  const { itemId } = useParams();

  const dispatch = useDispatch();

  const itemDetails = useSelector(
    state => ItemsSelectors.getItem(state, itemId)
  ) || {};

  const { startPrice } = itemDetails;

  const latestBidNumber = itemDetails.bids?.length
    ? itemDetails.bids[itemDetails.bids.length - 1].amount
    : startPrice;

  const createNewBid = () => {
    const bidUpOneDollar = latestBidNumber + 1;
    dispatch(
      ItemsActions.createNewBid(itemId, itemDetails.groupId, itemDetails.eventId, bidUpOneDollar)
    );
  };

  const latestBid = latestBidNumber % 1 === 0
    ? latestBidNumber?.toString()
    : latestBidNumber?.toFixed(2);

  return (
    <Page
      title="New Bid"
      contentAlign="center"
    >
      <Grid container sx={{ height: "100%" }} justifyContent="center">
        <Stack sx={{ mt: 2 }}>
          <Typography variant="h5" color="primary" fontWeight="bold" sx={{ mt: 3 }}>
            {`Latest bid: $ ${latestBid}`}
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 2, ml: 1, mr: 1, height: 42 }}
            onClick={createNewBid}
            disableElevation
          >
            Bid up $1
          </Button>
        </Stack>
      </Grid>
    </Page>
  );
};

export default ItemDetailsPage;
