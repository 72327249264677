import { Map, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isLoggingIn: false,
  loginMessage: "",
  isLoggingOut: false,
  loading: true,
  firebaseUser: null
});

const types = {
  attemptGoogleLogIn: "ATTEMPT_GOOGLE_LOG_IN",
  attemptFacebookLogIn: "ATTEMPT_FACEBOOK_LOG_IN",
  attemptAppleLogIn: "ATTEMPT_APPLE_LOG_IN",
  attemptDiscordLogIn: "ATTEMPT_DISCORD_LOG_IN",
  logInDiscordUser: "LOG_IN_DISCORD_USER",
  attemptEmailPasswordLogIn: "ATTEMPT_EMAIL_PASSWORD_LOG_IN",
  attemptLogInSuccess: "ATTEMPT_LOG_IN_SUCCESS",
  attemptLogInFailure: "ATTEMPT_LOG_IN_FAILURE",
  setLoggedIn: "SET_LOGGED_IN",
  setLoginMessage: "SET_LOGIN_MESSAGE",
  attemptLogOut: "ATTEMPT_LOG_OUT",
  logOutSuccess: "LOG_OUT_SUCCESS",
  logOutFailure: "LOG_OUT_FAILURE",
  finishLoading: "FINISH_LOADING",
  setFirebaseUser: "SET_FIREBASE_USER"
};

/* ********* Actions ********* */

const actions = {
  setFirebaseUser(data) {
    return {
      type: types.setFirebaseUser,
      data
    };
  },
  attemptGoogleLogIn(data) {
    return {
      type: types.attemptGoogleLogIn,
      data
    };
  },
  attemptFacebookLogIn(data) {
    return {
      type: types.attemptFacebookLogIn,
      data
    };
  },
  attemptAppleLogIn(data) {
    return {
      type: types.attemptAppleLogIn,
      data
    };
  },
  attemptDiscordLogIn(data) {
    return {
      type: types.attemptDiscordLogIn,
      data
    };
  },
  logInDiscordUser(code) {
    return {
      type: types.logInDiscordUser,
      code
    };
  },
  attemptEmailPasswordLogIn(data) {
    return {
      type: types.attemptEmailPasswordLogIn,
      data
    };
  },
  attemptLogInSuccess(data) {
    return {
      type: types.attemptLogInSuccess,
      data
    };
  },
  attemptLogInFailure(data) {
    return {
      type: types.attemptLogInFailure,
      data
    };
  },
  setLoginMessage(data) {
    return {
      type: types.setLoginMessage,
      data
    };
  },
  attemptLogOut() {
    return {
      type: types.attemptLogOut
    };
  },
  logOutSuccess() {
    return {
      type: types.logOutSuccess
    };
  },
  logOutFailure() {
    return {
      type: types.logOutFailure
    };
  },
  finishLoading() {
    return {
      type: types.finishLoading
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.attemptGoogleLogIn:
    return state.merge({ isLoggingIn: true, loginMessage: "" });
  case types.attemptFacebookLogIn:
    return state.merge({ isLoggingIn: true, loginMessage: "" });
  case types.attemptAppleLogIn:
    return state.merge({ isLoggingIn: true, loginMessage: "" });
  case types.attemptDiscordLogIn:
    return state.merge({ isLoggingIn: true, loginMessage: "" });
  case types.logInDiscordUser:
    return state.merge({ isLoggingIn: true, loginMessage: "" });
  case types.attemptEmailPasswordLogIn:
    return state.merge({ isLoggingIn: true, loginMessage: "" });
  case types.attemptLogInSuccess:
    return state.merge({ isLoggingIn: false });
  case types.attemptLogInFailure:
    return state.merge({
      isLoggingIn: false,
      loginMessage: action.data.message || JSON.stringify(action.data)
    });
  case types.setLoginMessage:
    return state.merge({ loginMessage: action.data });
  case types.attemptLogOut:
    return state.merge({ isLoggingOut: true });
  case types.logOutSuccess:
    return state.merge({ isLoggingOut: false });
  case types.logOutFailure:
    return state.merge({ isLoggingOut: false });
  case types.clearLogInMessage:
    return state.merge({ loginMessage: "" });
  case types.finishLoading:
    return state.merge({ loading: false });
  case types.setFirebaseUser:
    return state.merge({ firebaseUser: fromJS(action.data) });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const auth = state => state.get("auth");

const selectors = {
  getLoginMessage: state => auth(state).get("loginMessage"),
  getIsRefreshing: state => auth(state).get("isRefreshing"),
  getLoading: state => auth(state).get("loading"),
  getFirebaseUser: memoize(state => auth(state).get("firebaseUser")?.toJS()),
  getIsLoggedIn: state => auth(state).get("firebaseUser") !== null && auth(state).get("loading") === false,
  getIsLoggedOut: state => auth(state).get("firebaseUser") === null && auth(state).get("loading") === false
};

export {
  types as AuthTypes,
  reducer as AuthReducer,
  selectors as AuthSelectors
};
export default actions;
