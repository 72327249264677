import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import Branch from "branch-sdk";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import config from "app/config";
import { darkTheme } from "app/themes/theme";
import BottomErrorMessage from "app/components/bottom_error_message";
import GroupsActions, { GroupsSelectors } from "app/state/redux/groups_redux";
import { AuthSelectors } from "app/state/redux/auth_redux";
import Page from "../layouts/grid_page";

const withLocation = Node => function LocationProvider(props) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Node location={location} searchParams={searchParams} {...props} />;
};

class LoginPage extends Component {
  static propTypes = {
    fetchGroup: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.shape().isRequired,
    loginMessage: PropTypes.string.isRequired,
    requestedGroup: PropTypes.shape(),
    searchParams: PropTypes.instanceOf(URLSearchParams),
  };

  static defaultProps = {
    requestedGroup: null,
    searchParams: null
  };

  state = {
    appLink: "",
    affiliateCode: ""
  };

  componentDidMount() {
    const { fetchGroup, location, searchParams } = this.props;
    const query = {};
    if (location.state && location.state.groupId) {
      query.groupId = location.state.groupId;
    } else if (searchParams) {
      const affiliateCode = searchParams.get("affiliateCode");
      this.setState({ affiliateCode });
      query.affiliateCode = affiliateCode;
    }
    fetchGroup(query);
    Branch.init(config.BRANCH_KEY, {}, (err, data) => {
      if (!err) {
        this.setState({ appLink: data.data_parsed.app_link });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { loginMessage: prevLoginMessage } = prevProps;
    const { loginMessage } = this.props;
    // If new message scroll to bottom
    if (loginMessage && loginMessage !== prevLoginMessage) {
      // If account exists attempt to redirect user to app
      if (loginMessage.includes("This email is already in use")) {
        // eslint-disable-next-line no-alert
        if (window.confirm("An account already exists with this email.\nWould you like to login instead?")) {
          window.open(`${config.APP_LINK}/join`, "_blank");
        }
      }
    }
  }

  render() {
    const { isFetching, loginMessage, requestedGroup, isLoggedIn } = this.props;
    const { appLink, affiliateCode } = this.state;
    if (isLoggedIn) {
      return <Navigate to="/" replace />;
    }
    return (
      <Page
        title="Login"
        contentAlign="center"
        contentJustify="center"
      >
        <ThemeProvider theme={darkTheme}>
          {
            // Do not render page content until not fetching and does not have app
            !isFetching
              ? (
                <Container
                  maxWidth="sm"
                  sx={{
                    position: "relative",
                    height: "100%",
                    mt: 1,
                    mb: 1,
                    pl: 3,
                    pr: 3
                  }}
                >
                  <Outlet context={{ appLink, group: requestedGroup, affiliateCode }} />
                  <BottomErrorMessage message={loginMessage} />
                </Container>
              ) : null
          }
        </ThemeProvider>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: AuthSelectors.getIsLoggedIn(state),
  isFetching: GroupsSelectors.getIsFetching(state),
  loginMessage: AuthSelectors.getLoginMessage(state),
  requestedGroup: GroupsSelectors.getGroupDetails(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGroup: query => dispatch(GroupsActions.queryForGroup(query))
});

export default withLocation(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
