import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea
} from "@mui/material";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function EventCard(props) {
  const { name, image, startDate, endDate, id: eventId } = props;
  const navigate = useNavigate();
  const { groupId } = useParams();

  const startDateJs = new Date(startDate);
  const startDigital = startDateJs.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  const endDateJs = new Date(endDate);
  const endDigital = endDateJs.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  const dayOfWeek = daysOfWeek[startDateJs.getDay()];
  const shortDate = startDateJs.toLocaleDateString("en-US");
  return (
    <Card elevation={0} sx={{ width: 232, ml: 1, mr: 1, bgcolor: "transparent", backgroundImage: "none" }}>
      <CardActionArea onClick={() => navigate(`/c/${groupId}/events/${eventId}`)}>
        <CardMedia
          component="img"
          height="160"
          width="160"
          image={image}
          alt="Auction night!"
        />
        <CardContent sx={{ bgcolor: "transparent", backgroundImage: "none" }}>
          <Typography variant="h6" color="text.main">
            {name || "Upcoming Event"}
          </Typography>
          <Typography variant="body2" color="text.light">
            {`${startDigital} - ${endDigital}`}
          </Typography>
          <Typography variant="body2" color="text.light">
            {`${dayOfWeek}, ${shortDate}`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

EventCard.propTypes = {
  endDate: PropTypes.string,
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
};

EventCard.defaultProps = {
  endDate: null,
  image: null,
  name: "Upcoming Event",
  startDate: null
};

export default EventCard;
