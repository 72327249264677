import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea
} from "@mui/material";
import Flex from "app/components/flex";

function ItemCard(props) {
  const { id: itemId, name, price, image, type } = props;
  const { groupId } = useParams();
  const navigate = useNavigate();
  const itemType = type.substring(0, type.indexOf("_"));
  return (
    <Card elevation={0} sx={{ width: 164, ml: 1, mr: 1, bgcolor: "transparent", backgroundImage: "none" }}>
      <CardActionArea onClick={() => navigate(`/c/${groupId}/items/${itemId}`)}>
        <CardMedia
          component="img"
          height="160"
          width="160"
          image={image}
          alt="Rare Pickachu"
        />
        <CardContent sx={{ bgcolor: "transparent", backgroundImage: "none" }}>
          <Typography variant="subtitle1" color="text.main" noWrap>
            {name}
          </Typography>
          <Flex>
            <Typography variant="body2" color="primary" fontWeight="bold">
              {`$${price.toFixed(2)}`}
            </Typography>
            <Typography variant="caption" color="text.light" sx={{ ml: 1 }}>
              {itemType}
            </Typography>
          </Flex>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

ItemCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string
};

ItemCard.defaultProps = {
  image: null,
  name: "Item",
  price: 0,
  type: "market_item"
};

export default ItemCard;
