import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Stack,
  Container
} from "@mui/material";
import CommunityInfoActions, { CommunityInfoSelectors } from "app/state/redux/community_info_redux";
import config from "app/config";
import EventCard from "./components/event_card";
import ItemCard from "../../components/item_card";
import Page from "../layouts/grid_page";

const GroupLandingPage = function GroupLandingPage(props) {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { fetchCommunityInfo, groupDetails, items, soldItems, acceptedItems, events } = props;

  useEffect(() => {
    fetchCommunityInfo(groupId);
  }, []);

  return (
    <Page
      title="Community"
      contentAlign="center"
    >
      <Grid container sx={{ mt: 1, mb: 1, height: "100%" }}>
        <Grid container sx={{ m: 1, ml: 2 }}>
          <img alt="" width={150} src={`/${config.ASSET_PATH}/text_logo.png`} style={{ marginBottom: 10 }} />
        </Grid>
        <Grid container sx={{ m: 1, ml: 2, mr: 2, p: 2, pt: 2, pb: 2, bgcolor: "background.alternate", borderRadius: 4 }}>
          <Typography variant="h4" component="div" align="left" color="text.main" fontWeight="bold" sx={{ mb: 1 }}>
            You&apos;re Invited!
          </Typography>
          <Typography variant="h7" component="span" align="left" color="text.main">
            Join the
            <Typography fontWeight="bold" component="span">
              {` ${groupDetails?.name} `}
            </Typography>
            community marketplace. The one place for us to hang out, have fun,
            and buy, sell, trade - together!
          </Typography>
        </Grid>
        <Typography
          variant="h6"
          component="div"
          align="left"
          color="text.main"
          fontWeight="bold"
          sx={{ mb: 3, mt: 3, pl: 2 }}
        >
          {`Download the ${config.BRAND_NAME} app to:`}
        </Typography>
        <Grid container sx={{ mt: 1, mb: 1, p: 2, bgcolor: "background.alternate" }}>
          <Typography variant="h6" component="div" align="left" color="primary" fontWeight="bold" sx={{ mb: 1 }}>
            See the latest items
          </Typography>
          <Grid container sx={{ overflowX: "scroll" }}>
            <Stack direction="row">
              {items.map(item => (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  image={item.images[0]?.urlMap.medium}
                  price={item.startPrice}
                  type={item.type}
                />
              ))}
            </Stack>
          </Grid>
          {!items.length
            ? (
              <Typography variant="subtitle1" color="text.main">
                There are no new items to show
              </Typography>
            ) : null}
        </Grid>
        <Grid container sx={{ mt: 1, mb: 1, p: 2, bgcolor: "background.alternate" }}>
          <Typography variant="h6" component="div" align="left" color="primary" fontWeight="bold" sx={{ mb: 1 }}>
            What&apos;s been sold recently?
          </Typography>
          <Grid container sx={{ overflowX: "scroll" }}>
            <Stack direction="row">
              {soldItems.map(item => (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  image={item.images[0].urlMap.medium}
                  price={item.startPrice}
                  type={item.type}
                />
              ))}
            </Stack>
          </Grid>
          {!soldItems.length
            ? (
              <Typography variant="subtitle1" color="text.main">
                There are no new items to show
              </Typography>
            ) : null}
        </Grid>
        <Grid container sx={{ mt: 1, mb: 1, p: 2, bgcolor: "background.alternate" }}>
          <Typography variant="h6" component="div" align="left" color="primary" fontWeight="bold" sx={{ mb: 1 }}>
            Here&apos;s what people have traded in
          </Typography>
          <Grid container sx={{ overflowX: "scroll" }}>
            <Stack direction="row">
              {acceptedItems.map(item => (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  image={item.images[0].urlMap.medium}
                  price={item.startPrice}
                  type={item.type}
                />
              ))}
            </Stack>
          </Grid>
          {!acceptedItems.length
            ? (
              <Typography variant="subtitle1" color="text.main">
                There are no new items to show
              </Typography>
            ) : null}
        </Grid>
        <Grid container sx={{ mt: 1, mb: 1, p: 2, bgcolor: "background.alternate" }}>
          <Typography variant="h6" component="div" align="left" color="primary" fontWeight="bold" sx={{ mb: 1 }}>
            Join the next in-app event
          </Typography>
          <Grid container sx={{ overflowX: "scroll" }}>
            <Stack direction="row">
              {events.map(event => (
                <EventCard
                  key={event.id}
                  id={event.id}
                  name={event.name}
                  image={event.image}
                  startDate={event.startDate}
                  endDate={event.endDate}
                />
              ))}
            </Stack>
          </Grid>
          {!events.length
            ? (
              <Typography variant="subtitle1" color="text.main">
                There are no upcoming events to show
              </Typography>
            ) : null}
        </Grid>
        <Container sx={{ m: 2, height: 42, alignSelf: "flex-end" }}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={() => navigate("/register")}
            disableElevation
          >
            Join
          </Button>
        </Container>
      </Grid>
    </Page>
  );
};

GroupLandingPage.propTypes = {
  acceptedItems: PropTypes.arrayOf(PropTypes.shape()),
  events: PropTypes.arrayOf(PropTypes.shape()),
  fetchCommunityInfo: PropTypes.func.isRequired,
  groupDetails: PropTypes.shape(),
  items: PropTypes.arrayOf(PropTypes.shape()),
  soldItems: PropTypes.arrayOf(PropTypes.shape())
};

GroupLandingPage.defaultProps = {
  groupDetails: {},
  events: [],
  items: [],
  soldItems: [],
  acceptedItems: []
};

const mapStateToProps = state => ({
  groupDetails: CommunityInfoSelectors.getGroupDetails(state),
  events: CommunityInfoSelectors.getEvents(state),
  items: CommunityInfoSelectors.getItems(state),
  soldItems: CommunityInfoSelectors.getSoldItems(state),
  acceptedItems: CommunityInfoSelectors.getAcceptedItems(state)
});

const mapDispatchToProps = dispatch => ({
  fetchCommunityInfo: groupId => dispatch(CommunityInfoActions.fetchCommunityInfo(groupId))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupLandingPage);
