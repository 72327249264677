import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Stack,
  Button,
  Typography,
  Link,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import config from "app/config";
import RegistrationActions, { RegistrationSelectors } from "app/state/redux/registration_redux";
import { darkTheme } from "app/themes/theme";

function SocialFormSection(props) {
  const { group, affiliateCode } = useOutletContext();
  const discordUser = useSelector(state => RegistrationSelectors.getDiscordUser(state));
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [username, setUsername] = useState(discordUser?.username || "");

  const { attemptRegister } = props;

  const handlePolicyAcceptedChange = e => setPolicyAccepted(e.currentTarget.checked);
  const handleUsernameChange = e => setUsername(e.currentTarget.value);

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    const groupId = group ? group.id : null;
    const groupName = group ? group.name : null;
    const groupIcon = group ? group.icon : null;

    attemptRegister({
      username,
      policyAccepted,
      groupId,
      affiliateCode,
      groupName,
      groupIcon
    });
  };

  return (
    <Stack alignSelf="stretch">
      <Typography variant="h6" color="primary" align="center" fontWeight="bold" sx={{ mt: 10, mb: 2 }}>
        Create a Username
      </Typography>
      <TextField
        variant="outlined"
        label="Username"
        value={username}
        fullWidth
        autoFocus
        sx={{ mt: 10, mb: 1 }}
        helperText="This is used as your name in app."
        color="primary"
        onChange={handleUsernameChange}
      />
      <FormControlLabel
        sx={{ mt: 4, mb: 1 }}
        control={(
          <Checkbox
            checked={policyAccepted}
            onChange={handlePolicyAcceptedChange}
            color="secondary"
            name="policyAccepted"
          />
        )}
        label={(
          <Typography color="text.main">
            <span>{`By checking, you agree to ${config.BRAND_NAME}'s `}</span>
            <Link
              href={`${config.WEBSITE_URL}/user-agreements`}
              target="_blank"
              rel="noopener noreferrer"
              color="text.link"
            >
              User Agreement and all other policies
            </Link>
            <span>.</span>
          </Typography>
        )}
      />
      <Button
        variant="contained"
        fullWidth
        color="primary"
        sx={{ "&.Mui-disabled": darkTheme.disabledPrimaryButton, mt: 8 }}
        disabled={!username || !username.length}
        size="large"
        onClick={handleRegisterSubmit}
        disableElevation
      >
        Submit
        <ArrowForwardIcon sx={{ ml: 1 }} />
      </Button>
    </Stack>
  );
}

SocialFormSection.propTypes = {
  attemptRegister: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  attemptRegister: registerData => dispatch(RegistrationActions.attemptRegister(registerData)),
});

export default connect(null, mapDispatchToProps)(SocialFormSection);
