import { call, put, takeLatest, delay, select } from "redux-saga/effects";
import Branch from "branch-sdk";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  createUserWithEmailAndPassword,
  OAuthProvider
} from "firebase/auth";
import api from "app/services/api";
import config from "app/config";
import NavigationService from "app/services/navigation_service";
import getMobileOperatingSystem, { ANDROID, IOS } from "app/services/get_mobile_os";
import RegistrationActions, { RegistrationTypes as types, RegistrationSelectors } from "app/state/redux/registration_redux";
import { getErrorMessage, getRspErrMessage } from "./helpers/errors";

const APP_STORE_WAITING_PERIOD = 5000;

const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const appleAuthProvider = new OAuthProvider("apple.com");

async function createAppStoreLinkWithAuthCredentials(
  appStoreUrl,
  customAuthToken,
  groupName,
  groupIcon
) {
  try {
    await new Promise((resolve, reject) => {
      Branch.init(config.BRANCH_KEY, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
    const linkData = {
      data: {
        $og_title: groupName,
        $og_image_url: groupIcon,
        customAuthToken
      },
    };
    const link = await new Promise((resolve, reject) => {
      Branch.link(linkData, (err, newLink) => {
        if (err) {
          reject(err);
        } else {
          resolve(newLink);
        }
      });
    });
    return link;
  } catch (e) {
    const err = getErrorMessage(e);
    throw new Error(err);
  }
}

async function checkEmailForAvailability(email) {
  const response = await api.validateEmail({ email });
  return response;
}

async function checkUsernameForAvailability(username) {
  const response = await api.validateUsername({ username });
  return response;
}

async function validateSocialAuthEmail(email) {
  if (email) {
    const emailResponse = await checkEmailForAvailability(email);
    if (emailResponse.status >= 400) {
      const err = getRspErrMessage(emailResponse);
      throw new Error(err);
    }
  }
}

async function signUpWithGoogle() {
  const auth = getAuth();
  const response = await signInWithPopup(auth, googleAuthProvider);
  await validateSocialAuthEmail(response?.user?.email);
}

async function signUpWithFacebook() {
  const auth = getAuth();
  const response = await signInWithPopup(auth, facebookAuthProvider);
  await validateSocialAuthEmail(response?.user?.email);
}

async function signUpWithApple() {
  const auth = getAuth();
  const response = await signInWithPopup(auth, appleAuthProvider);
  await validateSocialAuthEmail(response?.user?.email);
}

async function signUpWithEmail(email, password, username) {
  try {
    const auth = getAuth();

    const emailResponse = await checkEmailForAvailability(email);
    if (emailResponse.status >= 400) {
      const err = getRspErrMessage(emailResponse);
      throw new Error(err);
    }

    const usernameResponse = await checkUsernameForAvailability(username);
    if (usernameResponse.status >= 400) {
      const err = getRspErrMessage(usernameResponse);
      throw new Error(err);
    }

    await createUserWithEmailAndPassword(auth, email, password);
  } catch (err) {
    /* If Firebase account associated with email exists but Auxxit account doesn't,
       then continue with registration (User claims unmatched Firebase account) */
    if (err.code !== "auth/email-already-in-use") {
      throw err;
    }
  }
}

function* goToAppStorePageAndWait(appStoreUrl) {
  yield call(NavigationService.toAppStorePage);
  yield delay(APP_STORE_WAITING_PERIOD);
  window.location = appStoreUrl;
}

function* validateEmail(action) {
  try {
    const email = action.data;

    yield call(api.validateEmail, { email });

    yield put(RegistrationActions.validateEmailSuccess());
  } catch (e) {
    const err = (e.response && e.response.data) || e.message;
    yield put(RegistrationActions.validateEmailFailure(err));
  }
}

function* attemptGoogleRegister() {
  try {
    yield call(signUpWithGoogle);
    yield call(NavigationService.toRegisterSocialSection);
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(RegistrationActions.registerFailure(err));
  }
}

function* attemptFacebookRegister() {
  try {
    yield call(signUpWithFacebook);
    yield call(NavigationService.toRegisterSocialSection);
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(RegistrationActions.registerFailure(err));
  }
}

function* attemptAppleRegister() {
  try {
    yield call(signUpWithApple);
    yield call(NavigationService.toRegisterSocialSection);
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(RegistrationActions.registerFailure(err));
  }
}

function* attemptDiscordRegister() {
  try {
    window.location.href = config.DISCORD_URL;
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(RegistrationActions.registerFailure(err));
  }
}

function* attemptEmailPasswordRegister(action) {
  try {
    const { email: formEmail, password1, username } = action.data;
    yield call(signUpWithEmail, formEmail, password1, username);
    yield put(RegistrationActions.attemptRegister(action.data));
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(RegistrationActions.registerFailure(err));
  }
}

function* attemptRegister(action) {
  try {
    const {
      username, policyAccepted, email: formEmail, groupId, affiliateCode, groupName, groupIcon
    } = action.data;
    const { currentUser } = getAuth();
    const discordUser = yield select(RegistrationSelectors.getDiscordUser);
    const params = {
      email: formEmail || currentUser.email,
      username,
      policyAccepted,
      groupId,
      affiliateCode,
      discordId: discordUser?.id
    };
    if (currentUser) {
      params.uid = currentUser.uid;
    }
    const response = yield call(api.signUp, params);
    if (response.status >= 400) {
      const err = getErrorMessage(response);
      yield put(RegistrationActions.registerFailure(err));
      return;
    }
    yield put(RegistrationActions.registerSuccess());

    const { customAuthToken } = response.data;

    // if on an iOS device, navigate to the app store
    if (getMobileOperatingSystem() === IOS) {
      const appStoreLinkWithAuthCredentials = yield call(
        createAppStoreLinkWithAuthCredentials,
        config.iosStoreUrl,
        customAuthToken,
        groupName,
        groupIcon,
      );
      yield call(goToAppStorePageAndWait, appStoreLinkWithAuthCredentials);
    // if on an android device, navigate to the play store
    } else if (getMobileOperatingSystem() === ANDROID) {
      const appStoreLinkWithAuthCredentials = yield call(
        createAppStoreLinkWithAuthCredentials,
        config.playStoreUrl,
        customAuthToken,
        groupName,
        groupIcon,
      );
      yield call(goToAppStorePageAndWait, appStoreLinkWithAuthCredentials);
    } else {
      yield put(RegistrationActions.setRegistrationMessage("Thanks for registering!"));
    }
    // in all cases, navigate to home page
    yield call(NavigationService.toHomePage);
  } catch (e) {
    const err = getErrorMessage(e);
    yield put(RegistrationActions.registerFailure(err));
  }
}

export default function registrationSagas() {
  return [
    takeLatest(types.validateEmail, validateEmail),
    takeLatest(types.attemptRegister, attemptRegister),
    takeLatest(types.attemptEmailPasswordRegister, attemptEmailPasswordRegister),
    takeLatest(types.attemptGoogleRegister, attemptGoogleRegister),
    takeLatest(types.attemptFacebookRegister, attemptFacebookRegister),
    takeLatest(types.attemptAppleRegister, attemptAppleRegister),
    takeLatest(types.attemptDiscordRegister, attemptDiscordRegister)
  ];
}
