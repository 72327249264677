import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Stack,
  Avatar,
  Container
} from "@mui/material";
import config from "app/config";
import CommunityInfoActions, { CommunityInfoSelectors } from "app/state/redux/community_info_redux";
import ItemCard from "../../components/item_card";
import Page from "../layouts/grid_page";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const { EVENT_TYPE } = config;

const EventLandingPage = function EventLandingPage(props) {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { fetchEventItems, items } = props;

  const eventDetails = useSelector(
    state => CommunityInfoSelectors.getEventDetails(state, eventId)
  ) || {};

  useEffect(() => {
    fetchEventItems(eventId);
  }, []);

  const { startDate, endDate, type } = eventDetails;
  const startDateJs = new Date(startDate);
  const startDigital = startDateJs.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  const endDateJs = new Date(endDate);
  const endDigital = endDateJs.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  const dayOfWeek = daysOfWeek[startDateJs.getDay()];
  const shortDate = startDateJs.toLocaleDateString("en-US");

  const isAuction = type === EVENT_TYPE.AUCTION;

  return (
    <Page
      title="Event"
      contentAlign="center"
    >
      <Grid container sx={{ mt: 4, mb: 1, height: "100%" }}>
        <Grid container flexDirection="column" sx={{ m: 1, p: 1, pt: 2, pb: 2 }}>
          <Typography variant="h5" color="text.main" fontWeight="bold">
            {eventDetails.name || "Upcoming Event"}
          </Typography>
          <Typography variant="subtitle2" color="text.main" sx={{ mt: 2 }}>
            {eventDetails.description || "There's an upcoming event in this community!"}
          </Typography>
          <Grid container flexDirection="column" sx={{ mt: 3, mb: 1 }}>
            <Typography variant="h6" color="text.main" fontWeight="bold">
              {isAuction ? "Live Auction" : "Live Market"}
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Stack direction="row">
                <Avatar
                  alt={eventDetails.createdBy?.username}
                  src={eventDetails.createdBy?.avatar}
                  sx={{ mr: 2, width: 56, height: 56 }}
                />
                <Stack>
                  <Typography variant="h6" color="text.main">
                    {eventDetails.createdBy?.username}
                  </Typography>
                  <Typography variant="caption" color="text.light">
                    Host
                  </Typography>
                </Stack>
              </Stack>
              <Stack sx={{ mr: 2 }}>
                <Typography variant="body2" color="text.main">
                  {`${startDigital} - ${endDigital}`}
                </Typography>
                <Typography variant="body2" color="text.main">
                  {`${dayOfWeek}, ${shortDate}`}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid sx={{ mt: 3 }}>
            <Typography variant="h6" color="text.main" fontWeight="bold" sx={{ mb: 1 }}>
              Items Up For Auction
            </Typography>
            <Grid container>
              {items.map(item => (
                <ItemCard
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  image={item.images[0].urlMap.medium}
                  price={item.startPrice}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Container sx={{ m: 2, height: 42, alignSelf: "flex-end" }}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={() => navigate("/register")}
            disableElevation
          >
            Join
          </Button>
        </Container>
      </Grid>
    </Page>
  );
};

EventLandingPage.propTypes = {
  fetchEventItems: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape())
};

EventLandingPage.defaultProps = {
  items: []
};

const mapStateToProps = state => ({
  items: CommunityInfoSelectors.getEventItems(state)
});

const mapDispatchToProps = dispatch => ({
  fetchEventItems: eventId => dispatch(CommunityInfoActions.fetchPublicEventItems(eventId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EventLandingPage);
