const API_SUBDOMAIN = "api";
const PERIOD_DELIMITER = ".";
const MIN_SUBDOMAIN_TERMS = 3;

function assertNotLocal(hostname) {
  if (hostname.includes("localhost") || hostname.includes("192.168")) {
    throw new Error("Must inject API_URL, SOCKET_URL via webpack definePlugin");
  }
}

function getSubdomain(hostname) {
  const terms = hostname.split(PERIOD_DELIMITER).reverse();
  if (terms.length < MIN_SUBDOMAIN_TERMS) {
    return "";
  }
  return terms.slice(2).reverse().join(PERIOD_DELIMITER);
}

function getBaseUrl(urlFromConfig) {
  if (!urlFromConfig) {
    const originHostname = window.location.hostname;
    assertNotLocal(originHostname);
    const originSubdomain = getSubdomain(originHostname);
    if (!originSubdomain) {
      return `${window.location.protocol}//${API_SUBDOMAIN}.${originHostname}`;
    }
    return window.location.origin.replace(originSubdomain, API_SUBDOMAIN);
  }
  return urlFromConfig;
}

export default getBaseUrl;
