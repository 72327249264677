/* eslint-disable import/no-extraneous-dependencies */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import config from "../config";

export default function initFirebase() {
  try {
    const firebaseConfig = config.FIREBASE_CONFIG;
    const app = initializeApp(firebaseConfig);

    // Initialize auth
    getAuth(app);
  } catch (err) {
    console.error("Firebase failed to initialize", err);
  }
}
