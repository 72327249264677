import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthSelectors } from "app/state/redux/auth_redux";

function ProtectedRoute({ children }) {
  const isLoggedOut = useSelector(state => AuthSelectors.getIsLoggedOut(state));
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedOut) {
      navigate("/login");
    }
  }, [isLoggedOut]);
  const loading = useSelector(state => AuthSelectors.getLoading(state));
  if (loading) {
    return true;
  }
  return children;
}

export default ProtectedRoute;
