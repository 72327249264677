import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Stack,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AuthActions from "app/state/redux/auth_redux";
import { darkTheme } from "app/themes/theme";

function EmailFormSection(props) {
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");

  const { attemptEmailPasswordLogIn } = props;

  const handleEmailChange = e => setEmail(e.currentTarget.value);
  const handlePassword1Change = e => setPassword1(e.currentTarget.value);

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    attemptEmailPasswordLogIn({
      email,
      password1
    });
  };

  const validateForm = () => {
    if (email && password1) {
      return true;
    }
    return false;
  };

  return (
    <Stack alignSelf="stretch">
      <Typography variant="h6" color="primary" align="center" fontWeight="bold" sx={{ mt: 10, mb: 2 }}>
        Sign-In
      </Typography>
      <TextField
        variant="outlined"
        label="Email"
        value={email}
        fullWidth
        autoFocus
        sx={{ mt: 1, mb: 1 }}
        color="primary"
        onChange={handleEmailChange}
      />
      <TextField
        variant="outlined"
        type="password"
        label="Password"
        value={password1}
        fullWidth
        sx={{ mt: 1, mb: 1 }}
        color="primary"
        onChange={handlePassword1Change}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={!validateForm()}
        size="large"
        color="primary"
        sx={{ "&.Mui-disabled": darkTheme.disabledPrimaryButton, mt: 8 }}
        onClick={handleLoginSubmit}
        disableElevation
      >
        Sign In
        <ArrowForwardIcon sx={{ marginLeft: 1 }} />
      </Button>
    </Stack>
  );
}

EmailFormSection.propTypes = {
  attemptEmailPasswordLogIn: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  attemptEmailPasswordLogIn: loginData =>
    dispatch(AuthActions.attemptEmailPasswordLogIn(loginData)),
});

export default connect(null, mapDispatchToProps)(EmailFormSection);
