import socket from "./client";

const CHAT_ROOM_TYPE = "chat";

export function joinPrivateChat(roomId) {
  const socketClient = socket.getClient();
  socket.joinRoom(roomId, CHAT_ROOM_TYPE);
  socketClient.emit("join", { roomId, roomType: CHAT_ROOM_TYPE });
}

export function onChatMessageAdded(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("add-message", eventHandler);
}

export function onChatMessageDeleted(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("remove-message", eventHandler);
}

export function onUserJoined(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("user-joined", eventHandler);
}

export function onUserLeft(eventHandler) {
  const socketClient = socket.getClient();
  socketClient.on("user-left", eventHandler);
}

export function removePrivateChatListeners() {
  const socketClient = socket.getClient();
  socketClient.off("add-message");
  socketClient.off("remove-message");
  socketClient.off("user-joined");
  socketClient.off("user-left");
}

export function leavePrivateChat(roomId) {
  const socketClient = socket.getClient();
  socket.leaveRoom(roomId, CHAT_ROOM_TYPE);
  socketClient.emit("leave", { roomId, roomType: CHAT_ROOM_TYPE });
  removePrivateChatListeners();
}
