import { put, select, takeLatest } from "redux-saga/effects";
import api from "app/services/api";
/* eslint-disable import/no-named-as-default */
import callAuthApi from "app/services/call_auth_api";
import PostsActions, { PostsSelectors, PostsTypes } from "../redux/posts_redux";
import { GroupsSelectors } from "../redux/groups_redux";
import { CurrentUserSelectors } from "../redux/current_user_redux";
import ModelHelper from "./helpers/json_models";
import paginationHelpers from "../services/pagination";

const { filterOutDuplicates } = paginationHelpers;

function* fetchGroupPosts(action) {
  const groupId = action.data;
  try {
    const response = yield callAuthApi(api.fetchGroupPosts, groupId);

    const posts = ModelHelper.sanitizePosts(response.data.posts);
    yield put(PostsActions.fetchGroupPostsSuccess(posts));
  } catch (e) {
    yield put(PostsActions.fetchGroupPostsFailure());
  }
}

function* fetchMoreGroupPosts(action) {
  const groupId = action.data;
  try {
    const statePostsList = yield select(PostsSelectors.getGroupPosts);
    const filterDate = yield select(PostsSelectors.getNextPostsStartDate);
    if (!filterDate) return;

    const response = yield callAuthApi(api.fetchGroupPosts, groupId, { filterDate });

    const posts = ModelHelper.sanitizePosts(response.data.posts);
    const filteredPosts = filterOutDuplicates(posts, statePostsList);
    yield put(PostsActions.fetchMoreGroupPostsSuccess(filteredPosts));
  } catch (e) {
    yield put(PostsActions.fetchGroupPostsFailure());
  }
}

function* sendPost(action) {
  const { id: groupId } = yield select(GroupsSelectors.getGroupDetails);
  const { text, format, mentions } = action.data;
  try {
    yield callAuthApi(api.createGroupPost, groupId, {
      text,
      format,
      mentions,
    });

    yield put(PostsActions.sendPostSuccess());
  } catch (e) {
    yield put(PostsActions.sendPostFailure());
  }
}

function* newPostReceived(action) {
  const newPost = ModelHelper.sanitizePostSocket(action.data);
  const { id: currentUserId } = yield select(CurrentUserSelectors.getCurrentUser);
  if (currentUserId !== newPost.createdBy.id) {
    delete newPost.readCount;
  }
  yield put(PostsActions.newPostSuccess(newPost));
}

export default function postsSagas() {
  return [
    takeLatest(PostsTypes.fetchGroupPosts, fetchGroupPosts),
    takeLatest(PostsTypes.fetchMoreGroupPosts, fetchMoreGroupPosts),
    takeLatest(PostsTypes.sendPost, sendPost),
    takeLatest(PostsTypes.newPostReceived, newPostReceived),
  ];
}
