import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Grid
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import config from "app/config";
import { darkTheme } from "app/themes/theme";
import AuthActions from "app/state/redux/auth_redux";
import Page from "../layouts/grid_page";

// https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
let didInit = false;

const DiscordRedirectPage = function DiscordRedirectPage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const code = searchParams.get("code");
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      if (code) {
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === "function") {
          window.ReactNativeWebView.postMessage(code);
        } else {
          dispatch(AuthActions.logInDiscordUser(code));
        }
      }
    }
  }, []);
  return (
    <Page
      title="Error"
      contentAlign="center"
      contentJustify="center"
    >
      <ThemeProvider theme={darkTheme}>
        <Grid container justifyContent="center">
          <img alt="" width={50} src={`/${config.ASSET_PATH}/icon_logo.png`} style={{ marginBottom: 10 }} />
        </Grid>
        <Typography variant="h6" component="div" align="center" color="text.main" fontWeight="bold" sx={{ marginBottom: 1 }}>
          Logging in with Discord
        </Typography>
        <Typography variant="h7" component="div" align="center" color="text.main" sx={{ marginBottom: 1 }}>
          You are being redirected...
        </Typography>
      </ThemeProvider>
    </Page>
  );
};

export default DiscordRedirectPage;
