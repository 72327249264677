import { Colors } from "app/themes";

const styles = {
  popup: {
    position: "absolute",
    backgroundColor: Colors.transparent,
    width: "100%",
    marginTop: 75,
    pointerEvents: "none"
  },
  messageWrapper: {
    borderRadius: 2,
    boxShadow: "0px 2px 2px 1px rgba(69, 69, 69, 0.2)",
    backgroundColor: Colors.white,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 30,
    paddingLeft: 30
  },
  message: {
    marginLeft: 5,
    fontSize: 14
  },
  transitionContainer: {
    transition: "opacity 500ms ease-in-out",
    opacity: 0,
    zIndex: 99999
  }
};

export const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

export default styles;
