import { sanitizeUser, sanitizeObjectIds } from "./helpers";

function sanitizeProfile(data) {
  const {
    lastModified,
    user,
    addressPrimary,
    sellerDisbursement,
    addressBook,
    paymentInfo,
    phone,
    firstName,
    lastName,
  } = data;
  return sanitizeObjectIds({
    ...sanitizeUser(user),
    addressPrimary,
    sellerDisbursement,
    addressBook,
    lastModified,
    paymentInfo,
    phone,
    firstName,
    lastName
  });
}

export default {
  sanitizeProfile
};
