import { Map } from "immutable";

const INIT_STATE = Map({
  message: "",
  expiresIn: 0,
  showMessage: false // 0 means hide message
});

const types = {
  setAppMessage: "SET_APP_MESSAGE",
  hideAppMessage: "HIDE_APP_MESSAGE"
};

/* ********* Actions ********* */

const actions = {
  setAppMessage(data) {
    return {
      type: types.setAppMessage,
      data
    };
  },
  hideAppMessage() {
    return {
      type: types.hideAppMessage
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.setAppMessage:
    return state.merge({
      message: action.data.message,
      expiresIn: action.data.expiresIn,
      showMessage: true
    });
  case types.hideAppMessage:
    return state.merge({
      showMessage: false
    });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const appState = state => state.get("appState");

const selectors = {
  getAppMessage: state => appState(state).get("message"),
  getAppMessageExpiration: state => appState(state).get("expiresIn"),
  getShowAppMessage: state => appState(state).get("showMessage")
};

export {
  types as AppStateTypes,
  reducer as AppStateReducer,
  selectors as AppStateSelectors
};
export default actions;
