import { Map, fromJS } from "immutable";

const INIT_STATE = Map({
  isFetching: false,
  isSubmitting: false,
  currentUser: null
});

const types = {
  subscribeToUserSocket: "SUBSCRIBE_TO_USER_SOCKET",
  subscribeToSocketRooms: "SUBSCRIBE_TO_SOCKET_ROOMS",
  fetchCurrentUser: "FETCH_CURRENT_USER",
  fetchCurrentUserSuccess: "FETCH_CURRENT_USER_SUCCESS",
  fetchCurrentUserFailure: "FETCH_CURRENT_USER_FAILURE",
  reset: "RESET"
};

/* ********* Actions ********* */

const actions = {
  subscribeToUserSocket(data) {
    return {
      type: types.subscribeToUserSocket,
      data
    };
  },
  subscribeToSocketRooms() {
    return {
      type: types.subscribeToSocketRooms
    };
  },
  fetchCurrentUser() {
    return {
      type: types.fetchCurrentUser
    };
  },
  fetchCurrentUserSuccess(data) {
    return {
      type: types.fetchCurrentUserSuccess,
      data
    };
  },
  fetchCurrentUserFailure() {
    return {
      type: types.fetchCurrentUserFailure
    };
  },
  reset() {
    return {
      type: types.reset
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchCurrentUser:
    return state.merge({ isFetching: true });
  case types.fetchCurrentUserSuccess:
    return state.merge({ isFetching: false, currentUser: fromJS(action.data) });
  case types.fetchCurrentUserFailure:
    return state.merge({ isFetching: false });
  case types.reset:
    return INIT_STATE;
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const user = state => state.get("currentUser");

const selectors = {
  getIsFetching: state => user(state).get("isFetching"),
  getCurrentUser: state => user(state).get("currentUser")?.toJS()
};

export {
  types as CurrentUserTypes,
  reducer as CurrentUserReducer,
  selectors as CurrentUserSelectors
};
export default actions;
