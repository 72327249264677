import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CardSimple from "app/components/card_simple";
import { ItemsSelectors } from "app/state/redux/items_redux";

function ItemCardSimple(props) {
  const { id: itemId } = props;
  const item = useSelector(state => ItemsSelectors.getItem(state, itemId));
  if (!item) {
    return null;
  }
  const iconUrl = item.imgUrls && item.imgUrls.length ? item.imgUrls[0].medium : item.images[0];
  return (
    <CardSimple
      name={item.name || "Event"}
      image={iconUrl}
      route={`/i/${itemId}`}
    />
  );
}

ItemCardSimple.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ItemCardSimple;
