import axios from "axios";
import config from "app/config";
import getBaseUrl from "app/services/get_base_url";

const baseUrl = getBaseUrl(config.API_URL);

const client = axios.create({
  baseURL: `${baseUrl}/api/v2`,
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
  validateStatus: status => status < 400
});

client.setAccessToken = (accessToken) => {
  if (accessToken) {
    client.defaults.headers.common.Authorization = accessToken;
  } else {
    delete client.defaults.headers.common.Authorization;
  }
};

client.defaults.params = {};

export const publicClient = axios.create({
  baseURL: `${baseUrl}/public/api`,
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
  validateStatus: null
});

export default client;
