import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { darkTheme } from "app/themes/theme";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/PlayArrow";
import DiscoverIcon from "@mui/icons-material/SearchRounded";
import ActivityIcon from "@mui/icons-material/Notifications";
import PlusIcon from "@mui/icons-material/Add";
import ListItem from "@mui/material/ListItem";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AuthActions, { AuthSelectors } from "app/state/redux/auth_redux";

const drawerWidth = 140;

const drawerItems = [
  { text: "Home", icon: <HomeIcon />, route: "/" },
  { text: "Discover", icon: <DiscoverIcon />, route: "/" },
  { text: "Activity", icon: <ActivityIcon />, route: "/" },
];

function ResponsivePage(props) {
  const { title, children } = props;

  useEffect(() => {
    document.title = title;
  }, []);

  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const dispatch = useDispatch();

  const attemptLogOut = () => {
    dispatch(AuthActions.attemptLogOut());
  };

  const user = useSelector(state => AuthSelectors.getFirebaseUser(state));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddClose = () => {
    setAnchorEl(null);
  };

  const addIconButton = (
    <IconButton
      sx={{
        justifyContent: "center",
        bgcolor: "background.alternate",
        p: 2,
        borderRadius: 4,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
        mb: 1
      }}
      onClick={handleAddClick}
      aria-controls={open ? "add-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
    >
      <PlusIcon />
    </IconButton>
  );

  const drawer = (
    <div>
      <Toolbar />
      <List>
        <ListItem sx={{ justifyContent: "center" }} disablePadding>
          {addIconButton}
        </ListItem>
        {drawerItems.map(item => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton sx={{ flexDirection: "column" }} onClick={() => navigate(item.route)}>
              <ListItemIcon sx={{ justifyContent: "center" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
      <ThemeProvider theme={darkTheme}>
        <>
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                {user?.displayName}
              </Typography>
              <IconButton
                color="inherit"
                aria-label="log out"
                edge="start"
                onClick={attemptLogOut}
              >
                <LogoutIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </>
        <Box
          component="main"
          sx={{
            p: 3,
            width: {
              xs: "100%",
              sm: `calc(100% - ${drawerWidth}px)`
            },
            height: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Toolbar />
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {children}
          </Box>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="add-menu"
          open={open}
          onClose={handleAddClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                bgcolor: "background.alternate",
                backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1,
                ml: -2,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: "''",
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.alternate",
                  backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                }
              }
            }
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem disabled>
            New Listing
          </MenuItem>
          <MenuItem onClick={handleAddClose}>
            <ListItemIcon>
              <PlusIcon fontSize="small" />
            </ListItemIcon>
            For Auction
          </MenuItem>
          <MenuItem onClick={handleAddClose}>
            <ListItemIcon>
              <PlusIcon fontSize="small" />
            </ListItemIcon>
            For the Shop
          </MenuItem>
          <Divider />
          <MenuItem disabled>
            New Event
          </MenuItem>
          <MenuItem onClick={handleAddClose}>
            <ListItemIcon>
              <PlusIcon fontSize="small" />
            </ListItemIcon>
            Auction
          </MenuItem>
          <MenuItem onClick={handleAddClose}>
            <ListItemIcon>
              <PlusIcon fontSize="small" />
            </ListItemIcon>
            Live Market
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </Box>
  );
}

ResponsivePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default ResponsivePage;
