import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import { FcCheckmark } from "react-icons/fc";
import Flex from "app/components/flex";
import styles, { transitionStyles } from "./styles";

class PopupMessage extends PureComponent {
  static propTypes = {
    expiresIn: PropTypes.number,
    icon: PropTypes.func,
    message: PropTypes.string.isRequired,
    onExpire: PropTypes.func,
    show: PropTypes.bool
  };

  static defaultProps = {
    expiresIn: 0,
    onExpire: Function.prototype,
    show: false,
    icon: FcCheckmark
  };

  componentDidUpdate(prevProps) {
    const { expiresIn, onExpire, show } = this.props;
    if (!prevProps.show && show) {
      this.timeout = setTimeout(onExpire, expiresIn);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { message, show, icon: Icon } = this.props;
    return (
      <Transition in={show} timeout={500}>
        {state => (
          <div
            style={{
              ...styles.transitionContainer,
              ...transitionStyles[state]
            }}
          >
            <Flex style={styles.popup} flex={1} justifyContent="center" alignItems="center">
              <Flex style={styles.messageWrapper}>
                <Icon />
                <div style={styles.message}>{message}</div>
              </Flex>
            </Flex>
          </div>
        )}
      </Transition>
    );
  }
}

export default PopupMessage;
