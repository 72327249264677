import { put, takeLatest, call } from "redux-saga/effects";
import api from "app/services/api";
import CommunityInfoActions, { CommunityInfoTypes } from "app/state/redux/community_info_redux";
import ModelHelper from "./helpers/json_models";

function* fetchCommunityInfo(action) {
  try {
    const groupId = action.data;
    const response = yield call(api.fetchPublicGroupInfo, groupId);

    const data = ModelHelper.sanitize(response.data);
    yield put(CommunityInfoActions.fetchCommunityInfoSuccess(data));
  } catch (e) {
    yield put(CommunityInfoActions.fetchCommunityInfoFailure());
  }
}

function* fetchPublicEventItems(action) {
  try {
    const eventId = action.data;
    const response = yield call(api.fetchPublicEventItems, eventId);

    const data = response.data.map(i => ModelHelper.sanitize(i));
    yield put(CommunityInfoActions.fetchPublicEventItemsSuccess(data));
  } catch (err) {
    yield put(CommunityInfoActions.fetchPublicEventItemsFailure());
  }
}

export default function communityInfoSagas() {
  return [
    takeLatest(CommunityInfoTypes.fetchCommunityInfo, fetchCommunityInfo),
    takeLatest(CommunityInfoTypes.fetchPublicEventItems, fetchPublicEventItems)
  ];
}
