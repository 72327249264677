import client from "./client";

import authApi from "./auth_api";
import currentUserApi from "./current_user_api";
import groupsApi from "./groups_api";
import postsApi from "./posts_api";
import imagesApi from "./images_api";
import itemsApi from "./items_api";
import publicApi from "./public_api";
import eventsApi from "./events_api";

export default {
  client,
  ...authApi,
  ...currentUserApi,
  ...groupsApi,
  ...postsApi,
  ...imagesApi,
  ...itemsApi,
  ...publicApi,
  ...eventsApi
};
